import * as React from 'react';
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Modal,
} from '@mui/material';
import { toast } from 'react-toastify';
import {
  Div9,
  FormLoginStyled,
  FormLoginStyledContent,
  StyledBox,
  StyledContentButtons,
  StyledContentFooter,
  StyledContentModal,
  StyledDescription,
  StyledImageContainer,
  StyledModalHeader,
  StyledSubTitle,
  StyledTitle,
} from './styles';
import SimpleInput from '../../Login/Input';
import SubmitButton, { Button } from '../../../components/Button';
import { formatPhoneNumber, removePhoneNumberMask } from '../../../utils/mask';
import Plane from '../../../images/Plane';
import useAuthenticate from '../../../recoil/hooks/authenticate';

interface Props {
  open: boolean;
  isLoading: boolean;
  handleClose: () => void;
  handleSubmit: (values: any) => void;
}

const ModalNews = ({
  open,
  isLoading,
  handleClose,
  handleSubmit,
}: Props): React.ReactElement => {
  const { authenticate } = useAuthenticate();

  const [values, setValues] = React.useState({
    name: '',
    whatsapp: '',
    email: authenticate?.user?.email || '',
    terms: false,
  });

  React.useEffect(() => {
    if (!open) {
      setValues({
        name: '',
        whatsapp: '',
        email: authenticate?.user?.email || '',
        terms: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleChange = (event: any): void => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeWhatsapp = (event: any): void => {
    setValues({
      ...values,
      whatsapp: formatPhoneNumber(event.target.value),
    });
  };

  const submit = (e: any): void => {
    e.preventDefault();

    if (!values.name || !values.whatsapp || !values.email) {
      toast('Por favor, preencha os campos antes de submeter o formulário.', {
        type: 'error',
      });
      return;
    }

    if (!values.terms) {
      toast('Por favor, aceite os termos.', {
        type: 'error',
      });
      return;
    }

    const whatsapp = removePhoneNumberMask(values.whatsapp);

    handleSubmit({ ...values, whatsapp });
  };

  return (
    <Modal open={open}>
      <StyledBox>
        <StyledModalHeader>
          <StyledImageContainer>
            <Plane />
          </StyledImageContainer>
          <StyledTitle>Fique por dentro :)</StyledTitle>
        </StyledModalHeader>
        <StyledContentModal>
          <StyledSubTitle>Quer sair na frente do mercado?</StyledSubTitle>
          <StyledDescription>
            Reserve seu assento conforto para saber das promoções e garanta os
            melhores preços do mercado!
          </StyledDescription>

          <FormLoginStyled>
            <FormLoginStyledContent>
              <Div9>Nome*</Div9>
              <SimpleInput
                placeholder={'Informe seu nome'}
                name="name"
                onChange={handleChange}
                value={values.name}
              />

              <Div9>WhatsApp*</Div9>
              <SimpleInput
                placeholder={'(xx) x xxxx-xxxx'}
                name="whatsapp"
                onChange={handleChangeWhatsapp}
                value={values.whatsapp}
              />

              <Div9>E-mail principal*</Div9>
              <SimpleInput
                placeholder={'seu_email@dominio.com'}
                name="email"
                type="email"
                onChange={handleChange}
                value={values.email}
              />
            </FormLoginStyledContent>

            <StyledContentFooter>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={false}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          terms: e.target.checked,
                        })
                      }
                    />
                  }
                  label={
                    <Box component="div" fontSize={14}>
                      Estou de acordo em receber comunicações do Milhas na Conta
                    </Box>
                  }
                />
              </FormGroup>

              <StyledContentButtons>
                <div style={{ width: '45%' }}>
                  <Button onClick={handleClose} disabled={isLoading}>
                    {isLoading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <span>Fechar</span>
                    )}
                  </Button>
                </div>
                <div style={{ width: '45%' }}>
                  <SubmitButton onClick={submit} disabled={isLoading}>
                    {isLoading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <span>Confirmar</span>
                    )}
                  </SubmitButton>
                </div>
              </StyledContentButtons>
            </StyledContentFooter>
          </FormLoginStyled>
        </StyledContentModal>
      </StyledBox>
    </Modal>
  );
};

export default ModalNews;
