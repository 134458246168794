/* eslint-disable no-underscore-dangle */
import * as React from 'react';

import { toast } from 'react-toastify';
import Lottie from 'react-lottie';
import EditIcon from '@mui/icons-material/Edit';
import {
  StyledLoading,
  StyledHeaderTitlePage,
  StyledContentButtonsHeader,
} from './styles';

import useAuthenticate from '../../../recoil/hooks/authenticate';
import animationData from './loading.json';

import { ButtonWithIcon } from '../../../components/Button';

import FormSettingsLandingPage from '../../../components/FormSettingsLandingPage';
import {
  getLandingPageData,
  submitLandingPageData,
} from '../../../api/landing-page';
import SettingsLandingPage from '../../../components/SettingsLandingPage';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const LandingPage = (): React.ReactElement => {
  const [isLoading, setIsLoading] = React.useState(true);

  const [isEditing, setIsEditing] = React.useState(false);

  const { authenticate } = useAuthenticate();

  const [data, setData] = React.useState<any>(null);

  const getData = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await getLandingPageData(authenticate.token);

      setData(data.data);
    } catch (error) {
      toast(
        'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    } finally {
      setIsLoading(false);
    }
  }, [authenticate.token]);

  React.useEffect(() => {
    getData();
  }, [getData]);

  const handleSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      await submitLandingPageData(values, authenticate.token);

      setIsEditing(false);

      getData();
    } catch (error) {
      toast(
        'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelEdit = () => {
    getData();
    setIsEditing(false);
  };

  return (
    <div>
      <StyledHeaderTitlePage>
        <h1></h1>
        <StyledContentButtonsHeader>
          {!isEditing && (
            <ButtonWithIcon
              label="Editar"
              icon={<EditIcon />}
              onClick={() => setIsEditing(true)}
            />
          )}
        </StyledContentButtonsHeader>
      </StyledHeaderTitlePage>

      {isLoading && (
        <StyledLoading>
          <Lottie options={defaultOptions} height={150} width={150} />
          <div>Carregando</div>
        </StyledLoading>
      )}

      {isEditing ? (
        <FormSettingsLandingPage
          data={data}
          handleSubmit={handleSubmit}
          handleCancelEdit={handleCancelEdit}
        />
      ) : (
        <SettingsLandingPage data={data} />
      )}
    </div>
  );
};

export default LandingPage;
