import React, { ReactElement } from 'react';

import { StyledNotFound, StyledRoot, StyledTitle } from './styles';

import CardPromotion from '../CardPromotion';

interface Props {
  data: any[];
  handleEdit: (item: any) => void;
  handleChangeStatus: (item: any) => void;
}

const PromotionsList = ({
  data,
  handleEdit,
  handleChangeStatus,
}: Props): ReactElement => {
  return (
    <StyledRoot>
      <StyledTitle>Campanhas</StyledTitle>

      {!data?.length && (
        <StyledNotFound>Nenhuma campanha encontrada!</StyledNotFound>
      )}

      {data?.map((item, index) => (
        <CardPromotion
          key={index}
          item={item}
          onClick={() => handleEdit(item)}
          handleChangeStatus={() => handleChangeStatus(item)}
        />
      ))}
    </StyledRoot>
  );
};

export default PromotionsList;
