import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { StyleBox, StyleContent, StyleContentButton } from './styles';
import { Button } from '../Button';

interface Props {
  open: boolean;
}

export default function ModalErrorPermissionRequest({
  open,
}: Props): React.ReactElement {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/home');
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <StyleBox>
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            color="error"
          >
            Oops. Você está logado como Parceiro.
          </Typography>
          <StyleContent>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Não é possível fazer uma solicitação sem vincular um código. Por
              favor, faça login novamente informando o código da promoção!
            </Typography>
          </StyleContent>
          <StyleContentButton>
            <Button onClick={handleClose}>
              <span>Fechar</span>
            </Button>
          </StyleContentButton>
        </StyleBox>
      </Fade>
    </Modal>
  );
}
