import React, { FC, ReactElement } from 'react';

import PixIcon from '@mui/icons-material/Pix';
import {
  StyledActions,
  StyledCardPix,
  StyledContentCardInfo,
  StyledItem,
} from './styles';

import { formatMoney } from '../../utils/money';

interface Props {
  pix: any;
}

const CardPix: FC<Props> = ({ pix }): ReactElement => {
  // const { authenticate } = useAuthenticate();

  // const isAdmin = React.useMemo(
  //   () => ['admin'].includes(authenticate?.user?.role),
  //   [authenticate]
  // );
  return (
    <StyledCardPix>
      <StyledItem style={{}}>
        <StyledContentCardInfo>
          <div
            style={{
              width: '100%',
              textAlign: 'center',
            }}
          >
            {pix.id.substring(17, pix.id.length)} - {formatMoney(pix.value)}{' '}
            {/* {isAdmin ? ` - ${pix.identifier.toUpperCase()}` : ''} */}
          </div>
        </StyledContentCardInfo>
        <StyledActions
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 16,
          }}
        >
          <PixIcon htmlColor="#77B6A8" />
        </StyledActions>
      </StyledItem>
    </StyledCardPix>
  );
};

export default CardPix;
