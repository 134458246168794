import React, { HTMLInputTypeAttribute } from 'react';
import styled from 'styled-components';

const InputContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
`;

const InputField = styled('input')({
  borderRadius: '4px',
  border: '1px solid #ced4da',
  justifyContent: 'center',
  alignItems: 'start',
  whiteSpace: 'nowrap',
  padding: '13px 60px 13px 15px',
  backgroundColor: '#fff',
  width: '100%',
  fontSize: '14px',
  fontFamily: 'Poppins, sans-serif',

  '@media (max-width: 991px)': {
    whiteSpace: 'initial',
    maxWidth: '100%',
    paddingRight: '20px',
  },

  '&::placeholder': {
    color: 'rgba(21, 110, 121, 0.5)',
  },
  color: 'rgba(21, 110, 121, 1)',

  '&:disabled': {
    background: '#ccc !important',
    pointerEvents: 'none',
  },
});

interface SimpleInputProps {
  placeholder?: string;
  value?: string;
  onChange?: (event?: any) => void;
  name?: string;
  type?: HTMLInputTypeAttribute;
  disabled?: boolean;
}

const SimpleInput = ({
  placeholder,
  value,
  onChange,
  name,
  disabled,
  type = 'text',
}: SimpleInputProps): any => {
  return (
    <InputContainer>
      <InputField
        type={type}
        disabled={disabled}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
      />
    </InputContainer>
  );
};

export default SimpleInput;
