import styled from 'styled-components';

export const StyledContentCode = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 4,

  cursor: 'pointer',
  transition: '0.3s',

  '& div': {
    opacity: 0,
  },

  '&:hover div': {
    opacity: 1,
  },
});
