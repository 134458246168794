import * as React from 'react';
import { styled } from '@mui/material/styles';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import { IconButton, Menu, MenuItem } from '@mui/material';

const StyledContentIcon = styled('div')(() => ({
  display: 'flex',
  paddingRight: 10,
  boxSizing: 'border-box',
}));

interface Option {
  id: string;
  label: string;
  disabled?: boolean;
  icon?: React.ReactElement;
  onClick: (id: string) => void;
}

interface Props {
  id: string;
  options: Option[];
}

export const MoreOptions = ({ options, id }: Props): React.ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (cb?: () => void) => {
    setAnchorEl(null);

    if (cb) {
      cb();
    }
  };

  return (
    <React.Fragment>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={(event: any) => {
          event.stopPropagation();
          handleClose();
        }}
        disableScrollLock
      >
        {options.map((option, index) => (
          <MenuItem
            key={`${option.id}-${index}`}
            disabled={option.disabled}
            onClick={(event) => {
              event.stopPropagation();
              handleClose(() => option.onClick(id));
            }}
          >
            {option.icon && (
              <StyledContentIcon>{option.icon}</StyledContentIcon>
            )}
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default MoreOptions;
