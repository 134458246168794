import React, { FC, ReactElement, useState } from 'react';
import { Typography } from '@mui/material';

import {
  StyledCardContainer,
  StyledCardContent,
  StyledCardContentItem,
  StyledCardContentItemUsers,
  StyledCardOptions,
  StyledCardPromotion,
  StyledCardTitle,
  StyledItemCode,
  StyledLink,
} from './styles';

import { formatShortDate } from '../../utils/date';
import { formatNumber, formatNumberPercent } from '../../utils/number';
import { formatMoney } from '../../utils/money';
import MoreOptions from '../MoreOptions';

let timerCopy: ReturnType<typeof setTimeout>;

interface Props {
  item: any;
  onClick?: () => void;
  handleChangeStatus: () => void;
}

const CardPromotion: FC<Props> = ({
  item,
  onClick,
  handleChangeStatus,
}): ReactElement => {
  const mapStatusColor = (status: 'active' | 'inactive'): string => {
    const options = {
      active: '#79CC72',
      inactive: '#b2102f',
    };

    return options[status] || options.inactive;
  };

  const mapStatusText = (status: 'active' | 'inactive'): string => {
    const options = {
      active: 'Ativa',
      inactive: 'Inativa',
    };

    return options[status] || options.inactive;
  };

  const mapStatusCodeText = (status: 'active' | 'inactive'): string => {
    const options = {
      active: 'Ativo',
      inactive: 'Inativo',
    };

    return options[status] || options.inactive;
  };

  const [copied, setCopied] = useState('');

  const handleCopyCode = (event: any, code: string) => {
    if (event) {
      event.stopPropagation();
    }

    navigator.clipboard.writeText(code);

    setCopied(code);

    clearTimeout(timerCopy);

    timerCopy = setTimeout(() => {
      setCopied('');
    }, 1000);
  };

  const stopPropagation = (event: any) => {
    if (event) {
      event.stopPropagation();
    }
  };

  const makeUrl = (code: string): string => {
    return `https://milhasnaconta.com.br/#/login?code=${code}`;
  };

  return (
    <StyledCardPromotion onClick={onClick}>
      <StyledCardTitle>
        <Typography component="p" fontSize={16} fontWeight="bold">
          {item.title}
        </Typography>

        <MoreOptions
          id="teste"
          options={[
            {
              id: 'inactive',
              label:
                item.status === 'active'
                  ? 'Desativar promoção'
                  : 'Ativar promoção',
              onClick: () => handleChangeStatus(),
            },
          ]}
        />
      </StyledCardTitle>

      <StyledCardContainer>
        <StyledCardContent>
          <StyledCardContentItem style={{ width: 'calc(100% - 156px)' }}>
            Descrição: <span>{item.description}</span>
          </StyledCardContentItem>

          <StyledCardContentItem style={{ width: 'calc(100% - 156px)' }}>
            Página da promoção: <span>{item.url}</span>
          </StyledCardContentItem>

          <StyledCardContentItem>
            Programa de banco: <span>{item.bankProgram}</span>
          </StyledCardContentItem>

          <StyledCardContentItem>
            Programa da aérea: <span>{item.airlineProgram}</span>
          </StyledCardContentItem>

          <StyledCardContentItem>
            Data de início: <span>{formatShortDate(item.startDate)}</span>
          </StyledCardContentItem>

          <StyledCardContentItem>
            Data de término: <span>{formatShortDate(item.endDate)}</span>
          </StyledCardContentItem>

          <StyledCardContentItem>
            Bônus mínimo: <span>{formatNumberPercent(item.bonusMinimum)}</span>
          </StyledCardContentItem>

          <StyledCardContentItem>
            Bônus máximo: <span>{formatNumberPercent(item.bonusMaximum)}</span>
          </StyledCardContentItem>

          <StyledCardContentItem>
            Pontuação mínima: <span>{formatNumber(item.pointsMinimum)}</span>
          </StyledCardContentItem>

          <StyledCardContentItem>
            Pontuação máxima: <span>{formatNumber(item.pointsMaximum)}</span>
          </StyledCardContentItem>

          <StyledCardContentItem>
            Preço do parceiro: <span>{formatMoney(item.partnerPrice)}</span>
          </StyledCardContentItem>

          <StyledCardContentItem>
            Preço do cliente: <span>{formatMoney(item.customerPrice)}</span>
          </StyledCardContentItem>

          <StyledCardContentItem>
            Comissão: <span>{formatMoney(item.partnerFee)}</span>
          </StyledCardContentItem>

          <StyledCardContentItem>
            Administradores:
            <StyledCardContentItemUsers>
              {item?.userCode?.map((item: any) => (
                <StyledItemCode key={item.code} onClick={stopPropagation}>
                  <div>
                    {`${item?.user?.name} <${item?.user?.email}>`} {' - '}{' '}
                    <span style={{ fontWeight: 'bold' }}>{item.code}</span>
                    {' - '}
                    <span style={{ color: mapStatusColor(item.status) }}>
                      {mapStatusCodeText(item.status)}
                    </span>
                  </div>
                  <div style={{ display: 'flex', gap: 16 }}>
                    <StyledLink
                      style={{
                        color: item.code === copied ? '#79cc72' : undefined,
                      }}
                      onClick={(event: any) => handleCopyCode(event, item.code)}
                    >
                      {copied === item.code ? 'Copiado' : 'Copiar código'}
                    </StyledLink>

                    <StyledLink
                      style={{
                        color:
                          makeUrl(item.code) === copied ? '#79cc72' : undefined,
                      }}
                      onClick={(event: any) =>
                        handleCopyCode(event, makeUrl(item.code))
                      }
                    >
                      {makeUrl(item.code) === copied ? 'Copiado' : 'Copiar URL'}
                    </StyledLink>
                  </div>
                </StyledItemCode>
              ))}

              {!item?.userCode?.length && (
                <div style={{ color: '#aaa' }}>
                  Nenhum parceiro adicionado a essa campanha
                </div>
              )}
            </StyledCardContentItemUsers>
          </StyledCardContentItem>
        </StyledCardContent>

        <StyledCardOptions>
          <div
            style={{
              fontSize: 14,
              fontWeight: 500,
              background: mapStatusColor(item.status),
              color: '#fff',
              padding: 4,
              boxSizing: 'border-box',
              width: 150,
              textAlign: 'center',
              borderRadius: 4,
            }}
          >
            {mapStatusText(item.status)}
          </div>
        </StyledCardOptions>
        <div
          style={{
            fontSize: 14,
            fontWeight: 500,
            position: 'absolute',
            right: 0,
            bottom: 0,
          }}
        >
          Editar
        </div>
      </StyledCardContainer>
    </StyledCardPromotion>
  );
};

export default CardPromotion;
