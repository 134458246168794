import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { Div7, FormLoginStyled, StyleBox } from './styles';
import SimpleInput from './Input';
import useAuthenticate from '../../../recoil/hooks/authenticate';
import { RequestStatusEnum, requestStatusLabel } from '../utils';
import SimpleSelect from '../../Login/Select';
import SubmitButton from '../../../components/Button';
import { exportRequestV2 } from '../../../api/requestV2';

interface Props {
  open: boolean;
  handleClose: () => void;
}

export default function ModalExportReportRequests({
  open,
  handleClose,
}: Props): React.ReactElement {
  const { authenticate } = useAuthenticate();

  const [formData, setFormData] = React.useState({
    startDate: '',
    endDate: '',
    airlineProgram: 'all',
    status: RequestStatusEnum.all,
  });

  const [isLoading, setLoading] = React.useState(false);

  const handleChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      event.preventDefault();
      setLoading(true);

      const { startDate, endDate, status, airlineProgram } = formData;

      if (!startDate?.length || !endDate?.length) {
        toast('Por favor, preencha os campos antes de submeter o formulário.', {
          type: 'error',
        });
        return;
      }

      await exportRequestV2(
        startDate,
        endDate,
        status,
        airlineProgram,
        authenticate.token
      );

      toast('Relatório exportado com sucesso!', {
        type: 'success',
      });
      handleClose();
      setFormData({
        endDate: '',
        startDate: '',
        airlineProgram: 'all',
        status: RequestStatusEnum.all,
      });
    } catch (error: any) {
      const message =
        'Não foi possível gerar o relatório. Por favor, tente novamente!';
      toast(message, { type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      keepMounted={false}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <StyleBox>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            Exportar relatório
          </Typography>
          <FormLoginStyled>
            <Div7>Data de ínicio</Div7>
            <SimpleInput
              placeholder={'Data de início'}
              name="startDate"
              type="date"
              max={new Date().toLocaleDateString('fr-ca')}
              onChange={handleChange}
              value={formData.startDate}
            />
            <Div7>Data de fim</Div7>
            <SimpleInput
              placeholder={'Data de fim'}
              name="endDate"
              type="date"
              min={formData.startDate}
              max={new Date().toLocaleDateString('fr-ca')}
              onChange={handleChange}
              value={formData.endDate}
            />

            <Div7>Status</Div7>
            <SimpleSelect
              name="status"
              value={formData.status}
              onChange={handleChange}
              options={[
                {
                  value: RequestStatusEnum.all,
                  label: requestStatusLabel(RequestStatusEnum.all),
                },
                {
                  value: RequestStatusEnum.open,
                  label: requestStatusLabel(RequestStatusEnum.open),
                },
                {
                  value: RequestStatusEnum.pending,
                  label: requestStatusLabel(RequestStatusEnum.pending),
                },
                {
                  value: RequestStatusEnum.analysing,
                  label: requestStatusLabel(RequestStatusEnum.analysing),
                },
                {
                  value: RequestStatusEnum.confirmed,
                  label: requestStatusLabel(RequestStatusEnum.confirmed),
                },
                {
                  value: RequestStatusEnum.waitingCredit,
                  label: requestStatusLabel(RequestStatusEnum.waitingCredit),
                },
                {
                  value: RequestStatusEnum.completed,
                  label: requestStatusLabel(RequestStatusEnum.completed),
                },
                {
                  value: RequestStatusEnum.canceled,
                  label: requestStatusLabel(RequestStatusEnum.canceled),
                },
              ]}
            />

            <Div7>Programa</Div7>
            <SimpleSelect
              name="airlineProgram"
              value={formData.airlineProgram}
              onChange={handleChange}
              options={[
                {
                  label: 'Todos',
                  value: 'all',
                },
                {
                  label: 'Azul',
                  value: 'Azul',
                },
                {
                  label: 'Smiles',
                  value: 'Smiles',
                },
                {
                  label: 'Latam',
                  value: 'Latam',
                },
                {
                  label: 'Iberia',
                  value: 'Iberia',
                },
                {
                  label: 'Outros',
                  value: 'Outros',
                },
              ]}
            />

            <SubmitButton onClick={handleSubmit} disabled={isLoading}>
              {isLoading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <span>Exportar</span>
              )}
            </SubmitButton>
          </FormLoginStyled>
        </StyleBox>
      </Fade>
    </Modal>
  );
}
