import styled from 'styled-components';

export const StyledCardPromotion = styled('div')(({ onClick }) => ({
  boxShadow: '0px 0px 8px 1px rgba(0, 0, 0, 0.25)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  padding: '16px',
  position: 'relative',
  cursor: onClick ? 'pointer' : undefined,
  transition: '0.3s',
  fontFamily: 'Inter',
  color: '#79cc72',

  '&:hover': {
    transform: onClick ? 'scale(1.01)' : '',
  },
}));

export const StyledContentCardInfo = styled('div')({
  color: '#878A99',
  fontWeight: 300,
  display: 'flex',
  flexDirection: 'column',
  gap: 6,
  '> div': {
    display: 'flex',
    gap: 4,
  },
  '> div > div': {
    fontWeight: 400,
  },
});

export const StyledContentCard = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',

  '@media (max-width: 600px)': {
    flexDirection: 'column',
  },
});

export const StyledContentPaymentField = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const StyledCardTitle = styled('div')({
  boxSizing: 'border-box',
  paddingBottom: '4px',
  borderBottom: '1px solid #79cc7244',
  marginBottom: '8px',

  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const StyledCardContent = styled('div')({
  color: '#878A99',
  fontWeight: 400,

  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: 6,
});

export const StyledCardContentItem = styled('div')({
  '& span': {
    fontWeight: 300,
  },
});

export const StyledCardContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',

  position: 'relative',

  '@media (max-width: 600px)': {
    flexDirection: 'column',
  },
});

export const StyledCardOptions = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  paddingTop: 8,
  gap: 8,
  alignItems: 'flex-end',
  position: 'absolute',
  right: 0,
  top: 0,
});

export const StyledCardContentItemUsers = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 8,
  width: 'calc(100% - 100px)',
});

export const StyledEditButton = styled('div')({
  border: '1px solid',
  padding: '4px 16px',
  boxSizing: 'border-box',
  borderRadius: 4,
  cursor: 'pointer',
});

export const StyledItemCode = styled('div')({
  transition: '0.3s',
  cursor: 'auto',
  padding: 8,
  boxSizing: 'border-box',
  borderRadius: 8,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  '&:hover': {
    background: '#79cc7233',
  },
});

export const StyledLink = styled('div')(() => ({
  '&:hover': {
    textDecoration: 'underline',
  },
  transition: '0.5s',
  color: '#00bcd4',
  cursor: 'pointer',

  width: 110,
  textAlign: 'center',
}));
