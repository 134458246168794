import { FormGroup } from '@mui/material';
import styled from 'styled-components';

export const StyledRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
});

export const StyledTitle = styled('div')({
  color: '#555',
  fontSize: 18,
  fontWeight: 'bold',
});

export const StyledNotFound = styled('div')({
  padding: 48,
  textAlign: 'center',

  boxShadow: '0px 0px 8px 1px rgba(0, 0, 0, 0.25)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  position: 'relative',
  transition: '0.4s',
  fontFamily: 'Inter',
});

export const StyledItem = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: 16,
});

export const StyledContentButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledLabelSwitch = styled('div')({
  color: '#222',
});

export const StyledFormGroup = styled(FormGroup)({
  color: '#222',
  padding: '12px 8px 20px 8px',
});
