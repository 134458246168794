import * as React from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
} from '@mui/material';

import { toast } from 'react-toastify';
import {
  Div18,
  Div28,
  Div37,
  Div38,
  StyledButtonBack,
  Div32,
  StyledContentFormGroup,
} from './styles';

import { formatMoney } from '../../../utils/money';
import useAuthenticate from '../../../recoil/hooks/authenticate';
import { uploadFile } from '../../../api/request';
import HeaderRequest from '../../../components/HeaderRequest';
import AttachmentPayment from './Attachment';
import PixPayment from './Pix';
import { StatusPaymentRequest } from '../index';

interface AttachmentFile {
  id: string;
  name: string;
}

interface Props {
  resumeData: any;
  loading: boolean;
  goBack: () => void;
  paymentFiles: AttachmentFile[];
  handleSubmitPayments: (items: AttachmentFile[]) => void;
  checkPaymentHasBeenPaid: (id: string) => Promise<StatusPaymentRequest>;
  setPaymentsFiles: (items: AttachmentFile[]) => void;
}

const StepThreeRequest: React.FC<Props> = ({
  resumeData,
  loading,
  goBack,
  paymentFiles,
  checkPaymentHasBeenPaid,
  setPaymentsFiles,
  handleSubmitPayments,
}): React.ReactElement => {
  const { authenticate } = useAuthenticate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isUseAttachment, setUseAttachment] = React.useState(false);

  const removePaymentItem = (id: string): void => {
    setPaymentsFiles(paymentFiles.filter((item) => item.id !== id));
  };

  const handleUploadPaymentFile = async (files: FileList): Promise<void> => {
    try {
      setIsLoading(true);

      const result = await uploadFile(files, 'payment', authenticate.token);

      setPaymentsFiles([...paymentFiles, ...result.data]);
    } catch (error) {
      toast(
        'Não foi possível fazer o upload do arquivo. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (): void => {
    if (isLoading || loading) {
      return;
    }
    if (!paymentFiles.length) {
      toast('Por favor, anexe os comprovantes de pagamento.', {
        type: 'error',
      });
    } else {
      handleSubmitPayments(paymentFiles);
    }
  };

  return (
    <React.Fragment>
      <Div18>
        <HeaderRequest
          title="3. Pagamento"
          subtitle="Efetue o pagamento da sua solicitação"
        />

        <Div28>
          <div>Valor a ser pago:</div> {formatMoney(resumeData?.totalValue)}
        </Div28>
      </Div18>

      <StyledContentFormGroup disabled={Boolean(paymentFiles.length)}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                disabled={Boolean(paymentFiles.length)}
                value={isUseAttachment}
                checked={isUseAttachment}
                defaultChecked={isUseAttachment}
                onChange={(e) => setUseAttachment(e.target.checked)}
                sx={{
                  color: '#79cc72',
                  '&.Mui-checked': {
                    color: '#79cc72',
                  },
                  '&.Mui-disabled': {
                    color: '#aaa',
                  },
                }}
              />
            }
            label={
              <span style={{ fontSize: 14 }}>
                Já fix o pix e tenho um comprovante
              </span>
            }
          />
        </FormGroup>
      </StyledContentFormGroup>

      {isUseAttachment ? (
        <AttachmentPayment
          handleUploadPaymentFile={handleUploadPaymentFile}
          isLoading={isLoading || loading}
          paymentFiles={paymentFiles}
          removePaymentItem={removePaymentItem}
        />
      ) : (
        <PixPayment
          value={resumeData?.totalValue}
          checkPaymentHasBeenPaid={checkPaymentHasBeenPaid}
        />
      )}

      <Div32>
        <StyledButtonBack onClick={() => goBack()}>
          <Div38>Voltar</Div38>
        </StyledButtonBack>
        <Div37 onClick={() => handleSubmit()} disabled={!isUseAttachment}>
          <Div38>Finalizar</Div38>
          {isLoading || loading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <ArrowForwardIcon />
          )}
        </Div37>
      </Div32>
    </React.Fragment>
  );
};

export default StepThreeRequest;
