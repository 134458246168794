/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import api from '../services/api';

export const submitCampaignsRequest = async (
  data: any,
  token: string
): Promise<any> => {
  const result = await api.post(`/api/campaigns`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const submitEditCampaignRequest = async (
  campaignId: string,
  data: any,
  token: string
): Promise<any> => {
  const result = await api.put(`/api/campaigns/${campaignId}`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const handleChangeCampaignStatus = async (
  campaignId: string,
  status: string,
  token: string
): Promise<any> => {
  const result = await api.patch(
    `/api/campaigns/${campaignId}/status`,
    {
      status,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const getCampaignsRequest = async (
  token: string,
  status: string,
  search: string
): Promise<any> => {
  const result = await api.get(
    `/api/campaigns?status=${status}&search=${search}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export default {
  submitCampaignsRequest,
  getCampaignsRequest,
};
