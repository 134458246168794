export const formatDate = (date: Date | undefined): string => {
  if (!date) {
    return '-';
  }

  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  return new Intl.DateTimeFormat('pt-BR', options).format(new Date(date));
};

export const formatShortDate = (date: Date | undefined): string => {
  if (!date) {
    return '-';
  }

  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  return new Intl.DateTimeFormat('pt-BR', options).format(new Date(date));
};

export const formatOnlyDate = (date: Date | undefined): string => {
  if (!date) {
    return '-';
  }

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  return new Intl.DateTimeFormat('pt-BR', options).format(new Date(date));
};

export const formatOnlyHour = (date: Date | undefined): string => {
  if (!date) {
    return '-';
  }

  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
  };

  return new Intl.DateTimeFormat('pt-BR', options).format(new Date(date));
};

export const addHours = (date: Date | string, hours: number): Date => {
  const newDate = new Date(date);
  newDate.setHours(newDate.getHours() + hours);
  return newDate;
};

export const isRemainTimeExpired = (
  targetDate: Date,
  seconds = 15
): boolean => {
  const now = new Date().getTime();

  const targetTime = new Date(targetDate).getTime();

  const differenceInSeconds = (targetTime - now) / 1000;

  return differenceInSeconds < seconds;
};

export const calcRemainTime = (date: Date): string => {
  const now = new Date();
  const diff = new Date(date).getTime() - now.getTime();

  if (diff <= 0) {
    return '0s';
  }

  const minutes = Math.floor(diff / (1000 * 60));
  const seconds = Math.floor((diff % (1000 * 60)) / 1000);

  return `${minutes}m ${seconds}s`;
};

export const calculateRemainingPercentage = (
  creationDate: Date,
  expirationDate: Date
): number => {
  const now = new Date().getTime();
  const start = new Date(creationDate).getTime();
  const end = new Date(expirationDate).getTime();

  if (now >= end) {
    return 0;
  }

  if (now <= start) {
    return 100;
  }

  const totalDuration = end - start;
  const timeRemaining = end - now;

  const percentage = (timeRemaining / totalDuration) * 100;
  return Math.max(0, Math.min(100, percentage));
};

export const getSecondsDifference = (inputDate: Date): number => {
  const targetDate = new Date(inputDate);

  if (Number.isNaN(targetDate)) {
    throw new Error('Data inválida. Por favor, forneça uma data válida.');
  }

  const currentDate = new Date();

  const differenceInMilliseconds = Math.abs(
    currentDate.getTime() - targetDate.getTime()
  );

  const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);

  return differenceInSeconds;
};
