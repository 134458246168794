import api from '../services/api';

interface LandingPageRequest {
  imagesMobile: string[];
  imagesDesktop: string[];
  urlDestination: string;
  urlRegulation: string;
  campaignDescription: string;
  hasActiveCampaign: boolean;
}

export const submitLandingPageData = async (
  data: LandingPageRequest,
  token: string
): Promise<any> => {
  const result = await api.post(`/api/landing-page`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const getLandingPageData = async (token: string): Promise<any> => {
  const result = await api.get(`/api/landing-page`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const uploadFileLandingPage = async (
  files: FileList,
  token: string
): Promise<any> => {
  const formData = new FormData();

  // eslint-disable-next-line no-restricted-syntax
  for (const file of Array.from(files)) {
    formData.append('files', file);
  }

  const result = await api.post(`/api/landing-page/upload-file`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export default {
  submitLandingPageData,
  getLandingPageData,
  uploadFileLandingPage,
};
