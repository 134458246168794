/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { CircularProgress, Modal, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import {
  Div9,
  FormLoginStyled,
  StyledBox,
  StyledContentButtons,
} from './styles';
import SimpleInput from '../../Login/Input';
import SubmitButton, { Button } from '../../../components/Button';
import { getCampaignsRequest } from '../../../api/campaigns';
import useAuthenticate from '../../../recoil/hooks/authenticate';
import NewSelect from '../../NewRequestV3/StepOne/Form/Select';
import { moneyMask } from '../../../utils/money';

interface Props {
  open: boolean;
  title: string;
  isLoading: boolean;
  defaultValues?: {
    campaign: string;
    code: string;
    partnerValue: number;
    valueMiles: number;
  };
  handleClose: () => void;
  handleSubmit: (values: any) => void;
}

const ModalEditCode = ({
  open,
  title,
  isLoading,
  defaultValues,
  handleClose,
  handleSubmit,
}: Props): React.ReactElement => {
  const { authenticate } = useAuthenticate();

  const [campaigns, setCampaigns] = React.useState<any>(null);

  const [values, setValues] = React.useState({
    campaign: '',
    code: '',
    partnerValue: '',
    valueMiles: '',
  });

  const getData = React.useCallback(
    async (status: string, search: string) => {
      try {
        const data = await getCampaignsRequest(
          authenticate.token,
          status,
          search
        );

        setCampaigns(data.data);
      } catch (error) {
        toast(
          'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
          {
            type: 'error',
          }
        );
      }
    },
    [authenticate.token]
  );

  React.useEffect(() => {
    getData('active', '');
  }, [getData]);

  React.useEffect(() => {
    if (!open) {
      setValues({
        campaign: '',
        code: '',
        partnerValue: '',
        valueMiles: '',
      });
    }
  }, [open]);

  React.useEffect(() => {
    if (defaultValues) {
      setValues({
        ...defaultValues,
        partnerValue: moneyMask(defaultValues.partnerValue * 100),
        valueMiles: moneyMask(defaultValues.valueMiles * 100),
      });
    }
  }, [defaultValues]);

  const handleChange = (event: any): void => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeMoney = (event: any): void => {
    setValues({
      ...values,
      [event.target.name]: moneyMask(event.target.value),
    });
  };

  const submit = (e: any): void => {
    e.preventDefault();

    if (!values.campaign || !values.code) {
      toast('Por favor, preencha os campos antes de submeter o formulário.', {
        type: 'error',
      });
      return;
    }

    handleSubmit({ ...values });
  };

  return (
    <Modal open={open}>
      <StyledBox>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <FormLoginStyled>
          <NewSelect
            name="campaign"
            disabled
            label="Campanha *"
            options={campaigns?.map((item: any) => ({
              value: item._id,
              label: item.title,
            }))}
            value={values.campaign}
            onChange={handleChange}
          />

          <Div9>Código</Div9>
          <SimpleInput
            placeholder={'Código'}
            name="code"
            disabled
            onChange={handleChange}
            value={values.code}
          />

          <Div9>Preço do parceiro *</Div9>
          <SimpleInput
            placeholder="Preço do parceiro"
            name="partnerValue"
            onChange={handleChangeMoney}
            value={values.partnerValue}
          />

          <Div9>Preço do cliente *</Div9>
          <SimpleInput
            placeholder="Preço do cliente"
            name="valueMiles"
            onChange={handleChangeMoney}
            value={values.valueMiles}
          />

          <StyledContentButtons>
            <div style={{ width: '45%' }}>
              <Button
                onClick={handleClose}
                disabled={isLoading}
                style={{ marginTop: 10 }}
              >
                {isLoading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <span>Cancelar</span>
                )}
              </Button>
            </div>
            <div style={{ width: '45%' }}>
              <SubmitButton
                onClick={submit}
                disabled={isLoading}
                style={{ marginTop: 10 }}
              >
                {isLoading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <span>Salvar</span>
                )}
              </SubmitButton>
            </div>
          </StyledContentButtons>
        </FormLoginStyled>
      </StyledBox>
    </Modal>
  );
};

export default ModalEditCode;
