/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Lottie from 'react-lottie';
import LogoutIcon from '@mui/icons-material/Logout';
import EditIcon from '@mui/icons-material/Edit';
import AddLinkIcon from '@mui/icons-material/AddLink';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import {
  Div2,
  Div,
  StyledRequestsContainer,
  StyleContentTitleContainer,
  StyledAreaContainer,
  StyledPlaneContainer,
  StyledSpanContent,
  StyledContentContainer,
  StyledRequestsContainerTable,
  StyledLoading,
  StyledContentSearch,
} from './styles';

import Plane from '../../images/Plane';
import {
  createUser,
  editUser,
  getAllUsers,
  getCodeById,
  getUserById,
} from '../../api/request';
import useAuthenticate from '../../recoil/hooks/authenticate';
import { formatShortDate } from '../../utils/date';
import { maskCpf } from '../../utils/mask';
import NewLoggedHeader from '../../components/NewLoggedHeader';
import animationData from './loading.json';

import SearchInput from '../../components/SearchInput';
import { formatUserRole, formatUserStatus } from './utils';
import ModalEditUser from './ModalEditUser';
import { ButtonWithIcon } from '../../components/Button';
import ModalCreateUser from './ModalCreateUser';
import UserTableCollapse from './UserTableCollapse';
import ModalAddCode from './ModalAddCode';
import { createCode, editCode } from '../../api/usercodes';
import SimpleSelect from '../Login/Select';
import { StyledContentFilterStatus } from '../Home/styles';
import ModalConfirm from '../../components/ModalConfirm';
import { handleLogoutUsersRequest } from '../../api/authenticate';
import ModalEditCode from './ModalEditCode';
import { removeMask } from '../../utils/cpf';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Users = (): React.ReactElement => {
  const navigate = useNavigate();

  const [modalConfirm, setModalConfirm] = React.useState({
    open: false,
    title: '',
    description: '',
    onClose: () => null,
    onConfirm: () => null,
  });

  const [filter, setFilter] = React.useState('partner');
  const [search, setSearch] = React.useState('');

  const [isLoading, setIsLoading] = React.useState(true);

  const [isEditing, setIsEditing] = React.useState(false);
  const [isEditingCode, setIsEditingCode] = React.useState(false);
  const [isCreating, setIsCreating] = React.useState(false);

  const [isCreatingCode, setCreatingCode] = React.useState(false);

  const { authenticate } = useAuthenticate();

  const [data, setData] = React.useState([]);

  const [userSelected, setUserSelected] = React.useState<any>();

  const [codeSelected, setCodeSelected] = React.useState<any>();

  const getData = React.useCallback(
    async (search?: string, filter?: string) => {
      try {
        setIsLoading(true);
        const data = await getAllUsers(authenticate.token, search, filter);

        setData(data.users);
      } catch (error) {
        toast(
          'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
          {
            type: 'error',
          }
        );
      } finally {
        setIsLoading(false);
      }
    },
    [authenticate.token]
  );

  const getUser = React.useCallback(
    async (id: string): Promise<any> => {
      try {
        const data = await getUserById(authenticate.token, id);

        return data;
      } catch (error: any) {
        toast(
          'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
          {
            type: 'error',
          }
        );
        return null;
      }
    },
    [authenticate.token]
  );

  const getCode = React.useCallback(
    async (id: string): Promise<any> => {
      try {
        const data = await getCodeById(authenticate.token, id);

        return data;
      } catch (error: any) {
        toast(
          'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
          {
            type: 'error',
          }
        );
        return null;
      }
    },
    [authenticate.token]
  );

  React.useEffect(() => {
    if (authenticate.isAuthenticate) {
      getData(search, filter);
    }
  }, [authenticate.isAuthenticate, getData, filter, search]);

  const handleSearch = (search: string): void => {
    setSearch(search);
  };

  const prepareData = (data: any) => {
    return data.map((item: any) => ({
      id: item._id,
      shortId: item._id.substring(17, item._id.length),
      name: item.name,
      cpf: maskCpf(item.cpf),
      role: formatUserRole(item.role),
      status: formatUserStatus(item.status),
      lastAccess: formatShortDate(item.lastAccess),
      email: item.email,
      allowEditCampaignValue: item.allowEditCampaignValue ? 'Sim' : 'Não',
    }));
  };

  const handleSelectUserToEdit = async (id: string): Promise<void> => {
    const data = await getUser(id);

    setUserSelected(data.user);

    setIsEditing(true);
  };

  const handleSelectCodeToEdit = async (id: string): Promise<void> => {
    const data = await getCode(id);

    setCodeSelected(data.code);

    setIsEditingCode(true);
  };

  const handleSelectUserToAddCode = async (id: string): Promise<void> => {
    const data = await getUser(id);

    setUserSelected(data.user);

    setCreatingCode(true);
  };

  const handleSubmitCreateuser = async (values: any): Promise<void> => {
    try {
      await createUser(
        {
          code: values.code,
          name: values.name,
          role: values.role,
          username: values.cpf,
          email: values.email,
          allowEditCampaignValue: values.allowEditCampaignValue,
        },
        authenticate.token
      );
      setIsCreating(false);

      getData(search, filter);
    } catch (error: any) {
      const message =
        error.response?.status === 409
          ? 'CPF ou E-mail já cadastrado!'
          : 'Não foi possível realizar sua solicitação. Por favor, tente novamente!';

      toast(message, {
        type: 'error',
      });
    }
  };

  const handleSubmitEdituser = async (values: any): Promise<void> => {
    try {
      if (userSelected) {
        await editUser(
          userSelected._id,
          {
            status: values.status,
            name: values.name,
            role: values.role,
            username: values.cpf,
            email: values.email,
            allowEditCampaignValue: values.allowEditCampaignValue,
          },
          authenticate.token
        );
      }
      setIsEditing(false);

      getData(search, filter);
    } catch (error: any) {
      const message =
        error.response?.status === 409
          ? 'CPF já cadastrado!'
          : 'Não foi possível realizar sua solicitação. Por favor, tente novamente!';

      toast(message, {
        type: 'error',
      });
    }
  };

  const handleSubmitEditCode = async (values: any): Promise<void> => {
    try {
      if (codeSelected) {
        await editCode(
          codeSelected._id,
          {
            partnerValue: Number(removeMask(values.partnerValue)) / 100,
            valueMiles: Number(removeMask(values.valueMiles)) / 100,
          },
          authenticate.token
        );
      }
      setIsEditingCode(false);

      getData(search, filter);
    } catch (error: any) {
      const message =
        'Não foi possível realizar sua solicitação. Por favor, tente novamente!';

      toast(message, {
        type: 'error',
      });
    }
  };

  const handleSubmitCreateCode = async (values: any): Promise<void> => {
    try {
      if (userSelected) {
        await createCode(
          userSelected._id,
          {
            campaign: values.campaign,
            code: values.code,
          },
          authenticate.token
        );
      }
      setCreatingCode(false);

      getData(search, filter);
    } catch (error: any) {
      const message =
        error.response?.status === 409
          ? 'Código já está sendo utilizado!'
          : 'Não foi possível realizar sua solicitação. Por favor, tente novamente!';

      toast(message, {
        type: 'error',
      });
    }
  };

  const handleLogoutUsers = async () => {
    try {
      await handleLogoutUsersRequest(authenticate.token);

      toast('Sessões encerradas!', {
        type: 'success',
      });
    } catch (error: any) {
      toast(
        'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    }
  };

  const handleLogoutUsersConfirm = async () => {
    setModalConfirm({
      open: true,
      title: 'Deslogar usuários',
      description:
        'Os usuários logados na plataforma nesse momento serão desconectados e será necessário logar novamente. Deseja continuar? ',
      onClose: () => {
        setModalConfirm((prev) => ({ ...prev, open: false }));
        return null;
      },
      onConfirm: () => {
        setModalConfirm((prev) => ({ ...prev, open: false }));
        handleLogoutUsers();
        return null;
      },
    });
  };

  return (
    <Div>
      <Div2>
        <NewLoggedHeader />

        <StyledContentSearch>
          <ButtonWithIcon
            label="Deslogar usuários"
            icon={<LogoutIcon />}
            onClick={() => handleLogoutUsersConfirm()}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: 20,
            }}
          >
            <StyledContentFilterStatus>
              <SimpleSelect
                name="status"
                value={filter}
                onChange={(event) => {
                  setFilter(event.target.value);
                }}
                options={[
                  {
                    value: 'partner',
                    label: 'Parceiro / Admin',
                  },
                  {
                    value: 'user',
                    label: 'Cliente',
                  },
                  {
                    value: 'all',
                    label: 'Todos',
                  },
                ]}
              />
            </StyledContentFilterStatus>

            <ButtonWithIcon
              label="Adicionar usuário"
              icon={<PersonAddAltIcon />}
              onClick={() => setIsCreating(true)}
            />
            <SearchInput handleSubmit={handleSearch} />
          </div>
        </StyledContentSearch>

        {isLoading && (
          <StyledLoading>
            <Lottie options={defaultOptions} height={150} width={150} />
            <div>Carregando</div>
          </StyledLoading>
        )}

        {!isLoading && !data.length && (
          <StyledRequestsContainer>
            <StyleContentTitleContainer>
              <span>Você ainda não tem solicitações :(</span>
            </StyleContentTitleContainer>
            <StyledContentContainer>
              <StyledAreaContainer
                onClick={() => {
                  navigate('/new-request');
                }}
              >
                <StyledPlaneContainer>
                  <Plane />
                </StyledPlaneContainer>
                <StyledSpanContent>
                  <strong>
                    <u>Clique aqui</u>
                  </strong>{' '}
                  para fazer sua primeira solicitação.
                </StyledSpanContent>
              </StyledAreaContainer>
            </StyledContentContainer>
          </StyledRequestsContainer>
        )}

        {!isLoading && !!data.length && (
          <StyledRequestsContainerTable>
            <UserTableCollapse
              data={prepareData(data)}
              handleSelectUserToAddCode={(id) => handleSelectUserToAddCode(id)}
              handleSelectCodeToEdit={(id) => handleSelectCodeToEdit(id)}
              rows={[
                'name',
                'email',
                'cpf',
                'role',
                'status',
                'lastAccess',
                'allowEditCampaignValue',
              ]}
              head={[
                'Nome',
                'E-mail',
                'CPF',
                'Permissão',
                'Status',
                'Último acesso',
                'Edita valor',
              ]}
              handleDownload={() => null}
              options={[
                {
                  id: 'edit',
                  label: 'Editar',
                  icon: <EditIcon fontSize="small" />,
                  onClick: (id) => handleSelectUserToEdit(id),
                },
                {
                  id: 'add-code',
                  label: 'Adicionar código',
                  icon: <AddLinkIcon fontSize="small" />,
                  onClick: (id) => handleSelectUserToAddCode(id),
                },
              ]}
            />
          </StyledRequestsContainerTable>
        )}
      </Div2>

      <ModalEditUser
        title={'Editar Usuário'}
        open={isEditing}
        handleSubmit={handleSubmitEdituser}
        isLoading={false}
        defaultValues={userSelected}
        handleClose={() => setIsEditing(false)}
      />

      <ModalAddCode
        title={'Adicionar código'}
        open={isCreatingCode}
        handleSubmit={handleSubmitCreateCode}
        isLoading={false}
        handleClose={() => setCreatingCode(false)}
      />

      <ModalEditCode
        title={'Editar código'}
        open={isEditingCode}
        handleSubmit={handleSubmitEditCode}
        isLoading={false}
        defaultValues={codeSelected}
        handleClose={() => setIsEditingCode(false)}
      />

      <ModalConfirm
        open={modalConfirm.open}
        description={modalConfirm.description}
        title={modalConfirm.title}
        onClose={modalConfirm.onClose}
        onConfirm={modalConfirm.onConfirm}
      />

      <ModalCreateUser
        title={'Adicionar Usuário'}
        open={isCreating}
        handleSubmit={handleSubmitCreateuser}
        isLoading={false}
        handleClose={() => setIsCreating(false)}
      />
    </Div>
  );
};

export default Users;
