import React from 'react';
import styled from 'styled-components';
import { StyledError, StyledLabel } from './styles';

const InputContainer = styled.div`
  display: flex;
`;

const InputField = styled('textarea')({
  borderRadius: '4px',
  border: '1px solid #ced4da',
  justifyContent: 'center',
  alignItems: 'start',
  whiteSpace: 'nowrap',
  padding: '13px 60px 13px 15px',
  backgroundColor: '#fff',
  width: '100%',
  fontSize: '14px',
  fontFamily: 'Poppins, sans-serif',
  resize: 'none',
  height: '150px',
  '@media (max-width: 991px)': {
    whiteSpace: 'initial',
    maxWidth: '100%',
    paddingRight: '20px',
  },

  '&::placeholder': {
    color: 'rgba(21, 110, 121, 0.5)',
  },
  color: 'rgba(21, 110, 121, 1)',

  '&:disabled': {
    background: '#ccc !important',
    pointerEvents: 'none',
  },
});

interface TextAreaProps {
  placeholder?: string;
  value?: string;
  onChange?: (event?: any) => void;
  name?: string;
  disabled?: boolean;
  label?: string;
  onBlur?: (event: any) => void;
  error?: string;
}

const TextArea = ({
  placeholder,
  value,
  onChange,
  name,
  disabled,
  label,
  onBlur,
  error,
}: TextAreaProps): any => {
  return (
    <React.Fragment>
      {!!label && <StyledLabel>{label}</StyledLabel>}
      <InputContainer>
        <InputField
          disabled={disabled}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          name={name}
          onBlur={onBlur}
        />
      </InputContainer>
      {!!error && <StyledError>{error}</StyledError>}
    </React.Fragment>
  );
};

export default TextArea;
