import { FormControl, InputAdornment, TextField } from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import styled from 'styled-components';

interface Props {
  defaultValue?: string;
  handleSubmit: (value: string) => void;
}

const StyledInputAdornment = styled(InputAdornment)`
  cursor: pointer;
`;

const SearchInput: FunctionComponent<Props> = ({
  defaultValue,
  handleSubmit,
}) => {
  const [showClearIcon, setShowClearIcon] = useState('0');
  const [value, setValue] = useState(defaultValue || '');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setShowClearIcon(event.target.value === '' ? '0' : '1');
    setValue(event.target.value);

    if (event.target.value === '') {
      handleSubmit('');
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (event.code === 'Enter') {
      handleSubmit(value);
    }
  };

  const handleClick = (): void => {
    setValue('');
    handleSubmit('');
    setShowClearIcon('0');
  };

  return (
    <div id="app">
      <FormControl>
        <TextField
          size="small"
          variant="outlined"
          value={value}
          defaultValue={defaultValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <StyledInputAdornment
                position="end"
                style={{ opacity: showClearIcon }}
                onClick={handleClick}
              >
                <ClearIcon />
              </StyledInputAdornment>
            ),
          }}
        />
      </FormControl>
    </div>
  );
};

export default SearchInput;
