/* eslint-disable no-underscore-dangle */
import * as React from 'react';

import { toast } from 'react-toastify';
import Lottie from 'react-lottie';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  StyledLoading,
  StyledHeaderTitlePage,
  StyledContentButtonsHeader,
} from './styles';

import useAuthenticate from '../../../recoil/hooks/authenticate';
import animationData from './loading.json';

import { ButtonWithIcon } from '../../../components/Button';

import PromotionsList from '../../../components/PromotionsList';
import FormPromotions from '../../../components/FormPromotions';
import { getAllUsers } from '../../../api/request';
import {
  getCampaignsRequest,
  handleChangeCampaignStatus,
  submitCampaignsRequest,
  submitEditCampaignRequest,
} from '../../../api/campaigns';
import SearchInput from '../../../components/SearchInput';
import SimpleSelect from '../../Login/Select';
import ModalConfirm from '../../../components/ModalConfirm';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Promotions = (): React.ReactElement => {
  const [modalConfirm, setModalConfirm] = React.useState({
    open: false,
    title: '',
    description: '',
    onClose: () => null,
    onConfirm: () => null,
  });
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const statusQuery = searchParams.get('status');
  const searchQuqery = searchParams.get('search');

  const [isLoading, setIsLoading] = React.useState(true);

  const [isEditing, setIsEditing] = React.useState(false);

  const { authenticate } = useAuthenticate();

  const [data, setData] = React.useState<any>(null);

  const [status, setStatus] = React.useState(statusQuery || 'active');
  const [search, setSearch] = React.useState(searchQuqery || '');

  const [users, setUsers] = React.useState<any[]>([]);

  const [campaignToEdit, setCampaignToEdit] = React.useState<any>(null);

  React.useEffect(() => {
    if (statusQuery) {
      setStatus(statusQuery);
    }
  }, [statusQuery]);

  const setQueryParam = (key: string, value: string | number) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set(key, value.toString());
    navigate(`?${newParams.toString()}`);
  };

  const getUsersRequest = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await getAllUsers(authenticate.token, '', 'partner');

      setUsers(data.users);
    } catch (error) {
      toast(
        'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    } finally {
      setIsLoading(false);
    }
  }, [authenticate.token]);

  React.useEffect(() => {
    getUsersRequest();
  }, [getUsersRequest]);

  const getData = React.useCallback(
    async (status: string, search: string) => {
      try {
        setIsLoading(true);
        const data = await getCampaignsRequest(
          authenticate.token,
          status,
          search
        );

        setData(data.data);
      } catch (error) {
        toast(
          'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
          {
            type: 'error',
          }
        );
      } finally {
        setIsLoading(false);

        setCampaignToEdit(null);
      }
    },
    [authenticate.token]
  );

  React.useEffect(() => {
    getData(status, search);
  }, [getData, status, search]);

  const handleSubmitAdd = async (values: any) => {
    try {
      setIsLoading(true);

      await submitCampaignsRequest(
        {
          ...values,
          startDate: new Date(values.startDate),
          endDate: new Date(values.endDate),
          customerPrice: Number(values.customerPrice) / 100,
          partnerPrice: Number(values.partnerPrice) / 100,
        },
        authenticate.token
      );

      setIsEditing(false);

      toast('Campanha adicionada com sucesso!', {
        type: 'success',
      });

      getData(status, search);
    } catch (error) {
      toast(
        'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitEdit = async (values: any) => {
    try {
      setIsLoading(true);

      await submitEditCampaignRequest(
        campaignToEdit._id,
        {
          ...values,
          startDate: new Date(values.startDate),
          endDate: new Date(values.endDate),
          customerPrice: Number(values.customerPrice) / 100,
          partnerPrice: Number(values.partnerPrice) / 100,
        },
        authenticate.token
      );

      setIsEditing(false);

      toast('Campanha editada com sucesso!', {
        type: 'success',
      });

      getData(status, search);
    } catch (error) {
      toast(
        'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values: any) => {
    if (campaignToEdit) {
      handleSubmitEdit(values);
    } else {
      handleSubmitAdd(values);
    }
  };

  const handleCancelEdit = () => {
    getData(status, search);
    setIsEditing(false);
  };

  const handleEditCampaign = (item: any) => {
    setCampaignToEdit(item);
    setIsEditing(true);
  };

  const handleChangeStatus = async (item: any, campaignStatus: string) => {
    try {
      await handleChangeCampaignStatus(
        item._id,
        campaignStatus,
        authenticate.token
      );

      getData(status, search);
    } catch (error) {
      toast(
        'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    }
  };

  const handleInactiveCampaignConfirm = async (item: any) => {
    setModalConfirm({
      open: true,
      title: 'Desativar campanha',
      description:
        'A campanha será desabilitada e todos os códigos vinculadas à ela serão inativados. Deseja continuar? ',
      onClose: () => {
        setModalConfirm((prev) => ({ ...prev, open: false }));
        return null;
      },
      onConfirm: () => {
        setModalConfirm((prev) => ({ ...prev, open: false }));
        handleChangeStatus(item, 'inactive');
        return null;
      },
    });
  };

  const handleActiveCampaignConfirm = async (item: any) => {
    setModalConfirm({
      open: true,
      title: 'Ativar campanha',
      description:
        'A campanha será ativada, você poderá vincular novos usuários e gerar novos códigos a ela. Deseja continuar? ',
      onClose: () => {
        setModalConfirm((prev) => ({ ...prev, open: false }));
        return null;
      },
      onConfirm: () => {
        setModalConfirm((prev) => ({ ...prev, open: false }));
        handleChangeStatus(item, 'active');
        return null;
      },
    });
  };

  const handleChangeStatusCampaign = (item: any) => {
    if (item.status === 'active') {
      handleInactiveCampaignConfirm(item);
    } else {
      handleActiveCampaignConfirm(item);
    }
  };

  return (
    <React.Fragment>
      <div>
        <StyledHeaderTitlePage>
          <h1></h1>
          <StyledContentButtonsHeader>
            {!isEditing && (
              <React.Fragment>
                <div style={{ marginTop: 8 }}>
                  <SimpleSelect
                    name="status"
                    value={status}
                    onChange={(event) => {
                      setStatus(event.target.value);
                      setQueryParam('status', event.target.value);
                    }}
                    options={[
                      {
                        value: 'all',
                        label: 'Todas',
                      },
                      {
                        value: 'active',
                        label: 'Ativas',
                      },
                      {
                        value: 'inactive',
                        label: 'Inativas',
                      },
                    ]}
                  />
                </div>
                <ButtonWithIcon
                  label="Adicionar"
                  icon={<AddIcon />}
                  onClick={() => setIsEditing(true)}
                />
                <SearchInput
                  handleSubmit={(value) => {
                    setSearch(value);
                    setQueryParam('search', value);
                  }}
                  defaultValue={search}
                />
              </React.Fragment>
            )}
          </StyledContentButtonsHeader>
        </StyledHeaderTitlePage>

        {isLoading && (
          <StyledLoading>
            <Lottie options={defaultOptions} height={150} width={150} />
            <div>Carregando</div>
          </StyledLoading>
        )}

        {isEditing ? (
          <FormPromotions
            users={users}
            campaignToEdit={campaignToEdit}
            handleSubmit={handleSubmit}
            handleCancelEdit={handleCancelEdit}
          />
        ) : (
          <PromotionsList
            data={data}
            handleEdit={handleEditCampaign}
            handleChangeStatus={handleChangeStatusCampaign}
          />
        )}
      </div>
      <ModalConfirm {...modalConfirm} />
    </React.Fragment>
  );
};

export default Promotions;
