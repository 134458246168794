/* eslint-disable no-param-reassign */

export const maskCnpj = (v: string): string => {
  v = v.replace(/\D/g, '');
  v = v.replace(/^(\d{2})(\d)/, '$1.$2');
  v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
  v = v.replace(/\.(\d{3})(\d)/, '.$1/$2');
  v = v.replace(/(\d{4})(\d)/, '$1-$2');
  return v;
};

export const maskCpf = (v: string): string => {
  v = v.replace(/\D/g, '');
  v = v.replace(/(\d{3})(\d)/, '$1.$2');
  v = v.replace(/(\d{3})(\d)/, '$1.$2');

  v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  return v;
};

export const formatPhoneNumber = (value: string): string => {
  let formattedValue = value.replace(/\D/g, '');

  if (formattedValue.length > 11) {
    formattedValue = formattedValue.slice(0, 11);
  }

  formattedValue = formattedValue.replace(/^(\d{2})(\d)/, '($1) $2');
  formattedValue = formattedValue.replace(/(\d{5})(\d)/, '$1-$2');

  return formattedValue;
};

export const removePhoneNumberMask = (value: string): string => {
  return value
    .replaceAll('(', '')
    .replaceAll(')', '')
    .replaceAll(' ', '')
    .replaceAll('-', '');
};
