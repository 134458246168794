import * as React from 'react';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';

import { CircularProgress } from '@mui/material';
import SubmitButton from '../../../components/Button';
import SimpleInput from './Input';

import {
  Div,
  Div2,
  Div3,
  Div4,
  Div5,
  Div6,
  Div7,
  BemVindo,
  MilhasConta,
  FormLoginStyled,
  PlaneContainer,
  StyledVersionHeader,
  StyledContentButton,
} from './styles';
import Plane from '../../../images/Plane';
import { cpfMask, removeCpfMask } from '../../../utils/cpf';
import config from '../../../config/config';

interface Props {
  isLoading: boolean;
  handleSubmit: (values: { user: string; code: string; token: string }) => void;
}

const LoginForm = ({ isLoading, handleSubmit }: Props): React.ReactElement => {
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  const [formData, setFormData] = React.useState({
    user: '',
    code: '',
  });

  const handleSubmitAction = async (event: any) => {
    event.preventDefault();

    const { user, code } = formData;

    if (!user?.length || !code?.length) {
      toast('Por favor, preencha os campos antes de submeter o formulário.', {
        type: 'error',
      });
      return;
    }

    const cpf = removeCpfMask(user);

    const token = await recaptchaRef?.current?.executeAsync();

    handleSubmit({
      user: cpf,
      code,
      token: token as string,
    });

    setTimeout(() => {
      recaptchaRef?.current?.reset();
    }, 200);
  };

  const handleChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCpf = (event: any): void => {
    setFormData({
      ...formData,
      user: cpfMask(event.target.value),
    });
  };

  return (
    <Div>
      <StyledVersionHeader>v{config.version}</StyledVersionHeader>
      <Div2>
        <Div3>Milhas na conta</Div3>

        <PlaneContainer>
          <Plane />
        </PlaneContainer>

        <Div4>
          <Div5>
            <BemVindo>Bem-vindo ao </BemVindo>

            <MilhasConta>Milhas na conta!</MilhasConta>
          </Div5>

          <Div6>Conecte-se para acessar o painel.</Div6>

          <FormLoginStyled>
            <Div7>Usuário</Div7>
            <SimpleInput
              placeholder={'Digite seu usuário (CPF ou CNPJ)'}
              name="user"
              onChange={handleChangeCpf}
              value={formData.user}
            />
            <Div7>Senha</Div7>
            <SimpleInput
              placeholder={'Digite sua senha'}
              name="code"
              type="password"
              onChange={handleChange}
              value={formData.code}
            />

            <StyledContentButton>
              <SubmitButton onClick={handleSubmitAction} disabled={isLoading}>
                {isLoading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <span>Entrar</span>
                )}
              </SubmitButton>
            </StyledContentButton>
          </FormLoginStyled>
        </Div4>
      </Div2>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={config.recaptcha}
      />
    </Div>
  );
};

export default LoginForm;
