import * as React from 'react';
import { toast } from 'react-toastify';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
} from '@mui/material';

import {
  Div25,
  Div37,
  Div38,
  StyledContentButtons,
  StyledContentCheckbox,
  StyledContentFormGroup,
  StyledContentWait,
  StyledLink,
  StyledSpanCheckBox,
} from './styles';

import useAuthenticate from '../../../recoil/hooks/authenticate';
import FormAccounts from './Form';
import SheetAccounts from './Sheet';
import config from '../../../config/config';
import ListAccounts from './List';
import {
  getLastRequestUser,
  validateAccountData,
  validateAccountSheet,
} from '../../../api/requestV2';
import HeaderRequest from '../../../components/HeaderRequest';
import { getSecondsDifference } from '../../../utils/date';

let intervalTimeToNewRequest: NodeJS.Timeout;
export interface AccountData {
  identification: string;
  phone: string;
  name: string;
  email: string;
  miles: number;
  valueMile: number;
}

interface Props {
  accountsData: AccountData[];
  loading: boolean;
  setAccountsData: (accountsData: AccountData[]) => void;
  handleSubmit: (accountsData: AccountData[]) => void;
  handleModalError: (message?: string, cause?: never[]) => void;
}

const StepOneRequest: React.FC<Props> = ({
  accountsData,
  setAccountsData,
  loading,
  handleSubmit,
  handleModalError,
}): React.ReactElement => {
  const [acceptTerms, setAcceptTerms] = React.useState(false);
  const [isUseSheet, setUseSheet] = React.useState(false);

  const [isRequestInLastMinute, setRequestInLastMinute] =
    React.useState<boolean>(false);

  const [timeToNewRequest, setTimeToNewRequest] = React.useState(0);

  const [forceResetForm, setResetForm] = React.useState(false);

  const { authenticate } = useAuthenticate();

  const [isLoading, setIsLoading] = React.useState(false);

  const handleRemoveAccountData = (index: number): void => {
    setAccountsData(
      accountsData.filter((_, i) => {
        return index !== i;
      })
    );
  };

  React.useEffect(() => {
    if (accountsData.length) {
      setAcceptTerms(true);
    }
    // Tem deixar desabiltado essa linha abaixo mesmo, pois o comportamento esperado depende dela estar desabilitada
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitAccounts = async (): Promise<void> => {
    if (!accountsData.length) {
      let message = 'Por favor, adicione as contas para continuar';
      if (isUseSheet) {
        message = 'Por favor, anexe os arquivos com as contas.';
      }
      toast(message, {
        type: 'error',
      });
      return;
    }

    if (!acceptTerms) {
      toast('Por favor, aceite os termos.', {
        type: 'error',
      });
      return;
    }

    handleSubmit(accountsData);
  };

  const makeUrlConfirm = React.useMemo((): React.ReactNode => {
    if (authenticate.user?.codeUrl && authenticate.user?.codeName) {
      return (
        <StyledSpanCheckBox>
          Todos os titulares foram cadastrados no
          <b>
            <StyledLink
              href={authenticate.user.codeUrl}
              target="_blank"
              rel="noreferrer"
            >
              site da campanha.
            </StyledLink>
          </b>
        </StyledSpanCheckBox>
      );
    }

    if (authenticate.user?.codeName) {
      return (
        <StyledSpanCheckBox>
          Todos os titulares serão cadastrados na promoção{' '}
          <b>{authenticate.user.codeName}</b>
        </StyledSpanCheckBox>
      );
    }

    return (
      <span>
        Confirmo que estou ciente da solicitação e da veracidade das informações
        enviadas!
      </span>
    );
  }, [authenticate]);

  const handleDownloadSheet = () => {
    window.open(
      `${config.urlServer}/assets/modelo-Milhas-na-Conta.xlsx`,
      '_blank'
    );
  };

  const handleSubmitAccountsData = async (values: AccountData) => {
    try {
      setIsLoading(true);

      await validateAccountData(
        values,
        authenticate.token,
        authenticate?.user?.code
      );

      setAccountsData([...accountsData, values]);

      setResetForm(true);

      setTimeout(() => {
        setResetForm(false);
      }, 200);
    } catch (error: any) {
      handleModalError(
        'Dados inválidos. Por favor, verifique os itens abaixo:',
        error?.response?.data?.errors?.length
          ? ([error?.response?.data?.errors] as never[])
          : undefined
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleUploadAccountFile = async (files: FileList): Promise<void> => {
    try {
      setIsLoading(true);
      const result = await validateAccountSheet(
        files,
        authenticate.token,
        authenticate?.user?.code
      );

      setAccountsData([...accountsData, ...result.data]);
    } catch (error: any) {
      if (error?.response?.data?.errors?.length) {
        handleModalError(
          'Dados inválidos. Por favor, verifique os itens abaixo:',
          error?.response?.data?.errors?.length
            ? ([error?.response?.data?.errors] as never[])
            : undefined
        );
      } else {
        handleModalError(
          error?.response?.data?.message,
          error?.response?.data?.cause
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getLastRequest = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const result = await getLastRequestUser(authenticate.token);

      if (result?.data) {
        const diff = getSecondsDifference(result.data);

        const TIME_TO_MAKE_A_NEW_REQUEST_IN_SECONDS = 60;

        setRequestInLastMinute(diff < TIME_TO_MAKE_A_NEW_REQUEST_IN_SECONDS);

        setTimeToNewRequest(TIME_TO_MAKE_A_NEW_REQUEST_IN_SECONDS - diff);
      } else {
        setRequestInLastMinute(false);
      }
    } catch (error: any) {
      const message = 'Não foi possível realizar essa ação!';
      toast(message, { type: 'error' });
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    getLastRequest();
  }, [getLastRequest]);

  React.useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isRequestInLastMinute) {
      interval = setInterval(() => {
        getLastRequest();
      }, 5000);
    }

    return () => clearInterval(interval);
  }, [getLastRequest, isRequestInLastMinute]);

  React.useEffect(() => {
    clearInterval(intervalTimeToNewRequest);

    if (timeToNewRequest > 0) {
      intervalTimeToNewRequest = setInterval(() => {
        setTimeToNewRequest((prev) => prev - 1);
      }, 1000);
    } else {
      setTimeToNewRequest(0);
    }

    return () => clearInterval(intervalTimeToNewRequest);
  }, [timeToNewRequest]);

  if (isRequestInLastMinute) {
    return (
      <StyledContentWait>
        <div>
          Por favor, aguarde alguns instantes para fazer uma nova solicitação.
        </div>
        <div>
          {timeToNewRequest
            ? `${timeToNewRequest} segundos`
            : 'Liberando acesso...'}
        </div>
      </StyledContentWait>
    );
  }

  return (
    <React.Fragment>
      <HeaderRequest
        title="1. Conta(s) do programa aéreo"
        subtitle="Informe os titulares que receberão as milhas"
      />

      <Div25>
        <StyledContentFormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  value={isUseSheet}
                  checked={isUseSheet}
                  defaultChecked={isUseSheet}
                  onChange={(e) => setUseSheet(e.target.checked)}
                  sx={{
                    color: '#79cc72',
                    '&.Mui-checked': {
                      color: '#79cc72',
                    },
                  }}
                />
              }
              label={
                <span style={{ fontSize: 14 }}>Usar planilha modelo.</span>
              }
            />
          </FormGroup>
        </StyledContentFormGroup>

        <SheetAccounts
          isLoading={isLoading || loading}
          handleUploadAccountFile={handleUploadAccountFile}
          handleDownloadSheet={handleDownloadSheet}
          hidden={!isUseSheet}
        />

        <FormAccounts
          handleSubmit={handleSubmitAccountsData}
          isLoading={isLoading}
          resetForm={forceResetForm}
          hidden={isUseSheet}
          pointsValue={authenticate?.user?.pointsValue}
          allowEditValue={authenticate?.user?.allowEditValue}
          pointsMinimum={authenticate?.user?.pointsMinimum}
          pointsMaximum={authenticate?.user?.pointsMaximum}
        />

        <ListAccounts
          isLoading={false}
          data={accountsData}
          removeData={handleRemoveAccountData}
        />

        <StyledContentCheckbox>
          <FormGroup>
            <FormControlLabel
              required
              control={
                <Checkbox
                  value={acceptTerms}
                  checked={acceptTerms}
                  defaultChecked={acceptTerms}
                  onChange={(e) => setAcceptTerms(e.target.checked)}
                  sx={{
                    color: '#79cc72',
                    '&.Mui-checked': {
                      color: '#79cc72',
                    },
                  }}
                />
              }
              label={makeUrlConfirm}
            />
          </FormGroup>
        </StyledContentCheckbox>
      </Div25>
      <StyledContentButtons>
        <Div37 onClick={() => !isLoading && !loading && handleSubmitAccounts()}>
          <Div38>Próximo</Div38>

          {isLoading || loading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <ArrowForwardIcon />
          )}
        </Div37>
      </StyledContentButtons>
    </React.Fragment>
  );
};

export default StepOneRequest;
