import * as React from 'react';
import { toast } from 'react-toastify';

import DownloadIcon from '@mui/icons-material/Download';

import { useNavigate } from 'react-router-dom';
import { createRequest, preCreateRequest } from '../../api/request';

import {
  Div,
  Div10,
  Div11,
  Div12,
  Div13,
  Div2,
  Div38,
  Div39,
  Div8,
  Div9,
  Img3,
  Img4,
  StyledButtonDonwload,
  StyledButtonDownloadIcon,
  StyledContentHeaderCard,
  StyledStepContentContainer,
  StyledStepItem,
  StyledStepItemContainer,
  StyledStepsContainer,
} from './styles';

import ModalErrorPermissionRequest from '../../components/ModalErrorPermissionRequest';
import ModalErrorUploadFiles from '../../components/ModalErrorUploadFiles';
import NewLoggedHeader from '../../components/NewLoggedHeader';
import config from '../../config/config';
import useAuthenticate from '../../recoil/hooks/authenticate';
import ModalNews from './ModalNews';

import { submitNewsletter } from '../../api/newsletter';
import StepOneRequest from './StepOne';
import StepTwoRequest from './StepTwo';
import StepThreeRequest from './StepThree';
import StepFourRequest from './StepFour';

interface Step {
  step: string;
  label: string;
  onClick: () => void;
}

interface AttachmentFile {
  id: string;
  name: string;
}

interface SuccessRequest {
  id: string;
  date: Date;
}

const NewRequest = (): React.ReactElement => {
  const navigate = useNavigate();

  const [modalError, setModalError] = React.useState({
    open: false,
    title: '',
    cause: [],
  });

  const [modalPermission, setModalPermission] = React.useState(false);

  const [modalNews, setModalNews] = React.useState(false);

  const { authenticate, updateUserNewsletter } = useAuthenticate();

  const [isLoading, setIsLoading] = React.useState(false);

  const [paymentFiles, setPaymentsFiles] = React.useState<AttachmentFile[]>([]);
  const [accountFiles, setAccountFiles] = React.useState<AttachmentFile[]>([]);

  const [successData, setSuccessData] = React.useState<SuccessRequest | null>(
    null
  );

  const [resumeData, setResumeData] = React.useState({
    averageMilesPerCpf: 0,
    biggestValue: 0,
    totalCpfs: 0,
    totalMiles: 0,
    totalPaymentFiles: 0,
    totalValue: 0,
  });

  React.useEffect(() => {
    if (['admin', 'partner'].includes(authenticate?.user?.role)) {
      setModalPermission(true);
    }
  }, [authenticate?.user?.role]);

  const [stepSelected, setStepSelected] = React.useState(0);

  const steps: Step[] = [
    { step: 'miles', label: 'Contas', onClick: () => setStepSelected(0) },
    {
      step: 'resume',
      label: 'Resumo',
      onClick: () => setStepSelected(1),
    },
    { step: 'payment', label: 'Pagamento', onClick: () => setStepSelected(2) },

    {
      step: 'confirmation',
      label: 'Confirmação',
      onClick: () => setStepSelected(3),
    },
  ];

  const handleSubmitAccounts = async (
    accounts: AttachmentFile[]
  ): Promise<void> => {
    try {
      setAccountFiles(accounts);

      setIsLoading(true);
      const data = {
        payments: [],
        accounts: accounts.map((item) => item.id),
      };

      const result = await preCreateRequest(
        data,
        authenticate.token,
        authenticate.user.code
      );

      setResumeData(result.data);

      setStepSelected(1);
    } catch (error) {
      toast(
        'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitPayments = async (
    payments: AttachmentFile[]
  ): Promise<void> => {
    try {
      setPaymentsFiles(payments);

      setIsLoading(true);

      const data = {
        payments: payments.map((item) => item.id),
        accounts: accountFiles.map((item) => item.id),
      };

      const result = await createRequest(
        data,
        authenticate.token,
        authenticate.user.code
      );

      setSuccessData(result.data);

      setStepSelected(3);

      if (!authenticate?.user?.isSubscribedNewsletter) {
        setTimeout(() => {
          setModalNews(true);
        }, 1500);
      }
    } catch (error) {
      toast(
        'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitResume = async (): Promise<void> => {
    setStepSelected(2);
  };

  const handleFinishRequest = (): void => {
    navigate('/home');
  };

  const openModalError = (message?: string, cause?: never[]) => {
    setModalError({
      open: true,
      title:
        message ||
        'Não foi possível fazer o upload do arquivo. Por favor, tente novamente!',
      cause: cause || [],
    });
  };

  const handleDownloadSheet = () => {
    window.open(
      `${config.urlServer}/assets/modelo-Milhas-na-Conta.xlsx`,
      '_blank'
    );
  };

  const handleSubmitNewsletter = async (values: any): Promise<void> => {
    submitNewsletter(values, authenticate.token);

    toast('Inscrição realizada com sucesso!', {
      type: 'success',
    });

    setModalNews(false);
    updateUserNewsletter();
  };

  return (
    <Div>
      <ModalErrorUploadFiles
        open={modalError.open}
        message={modalError.title}
        cause={modalError.cause}
        handleClose={() => setModalError({ ...modalError, open: false })}
      />

      <ModalNews
        open={modalNews}
        handleClose={() => setModalNews(false)}
        handleSubmit={handleSubmitNewsletter}
        isLoading={false}
      />

      <ModalErrorPermissionRequest open={modalPermission} />
      <Div2>
        <NewLoggedHeader />

        <Div8>
          <Div9>Crédito de milhas</Div9>
          <Div10>
            Inclua o comprovante de pagamento, Informe a quantidade de pontos da
            compra e envie para aprovação.{' '}
          </Div10>
        </Div8>
        <Div11>
          <Div12>
            <StyledContentHeaderCard>
              <Div13>Siga os passos para solicitar o crédito de milhas</Div13>
              {stepSelected === 0 && (
                <StyledButtonDonwload onClick={handleDownloadSheet}>
                  <Div38>Baixar planilha</Div38>
                  <StyledButtonDownloadIcon>
                    <DownloadIcon htmlColor="#79cc72" />
                  </StyledButtonDownloadIcon>
                </StyledButtonDonwload>
              )}
            </StyledContentHeaderCard>

            <StyledStepsContainer>
              <StyledStepItemContainer>
                {steps?.map(({ step, label }, index) => {
                  const isSelected = index === stepSelected;
                  return (
                    <StyledStepItem key={step} selected={isSelected}>
                      {isSelected ? (
                        <Img3
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/6a865ddd290f7dcdf95cfa3c9688e74ce5cf2480acc744066c984529e641018a?"
                        />
                      ) : (
                        <Img4
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/d746c7dc86f492df326d2db468bee968c64b3c116337e3fe8b2d9f3816e761e3?"
                        />
                      )}
                      <strong>Passo {index + 1}</strong> {label}
                    </StyledStepItem>
                  );
                })}
              </StyledStepItemContainer>

              <StyledStepContentContainer>
                {stepSelected === 0 && (
                  <StepOneRequest
                    loading={isLoading}
                    accountFiles={accountFiles}
                    setAccountsFiles={setAccountFiles}
                    handleSubmit={handleSubmitAccounts}
                    handleModalError={openModalError}
                  />
                )}
                {stepSelected === 1 && (
                  <React.Fragment>
                    <StepTwoRequest
                      goBack={() => setStepSelected(0)}
                      handleSubmitResume={handleSubmitResume}
                      isLoading={false}
                      resumeData={resumeData}
                    />
                  </React.Fragment>
                )}
                {stepSelected === 2 && (
                  <StepThreeRequest
                    loading={isLoading}
                    goBack={() => setStepSelected(1)}
                    handleSubmitPayments={handleSubmitPayments}
                    resumeData={resumeData}
                    setPaymentsFiles={setPaymentsFiles}
                    paymentFiles={paymentFiles}
                  />
                )}

                {stepSelected === 3 && (
                  <StepFourRequest
                    successData={successData}
                    handleFinishRequest={handleFinishRequest}
                  />
                )}
              </StyledStepContentContainer>
            </StyledStepsContainer>
          </Div12>
          <Div39></Div39>
        </Div11>
      </Div2>
    </Div>
  );
};

export default NewRequest;
