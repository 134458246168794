import React, { ReactElement, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import config from '../../config/config';
import useAuthenticate from '../../recoil/hooks/authenticate';
import Logo from '../Logo';
import {
  StyledHeader,
  StyledInnerHeader,
  StyledMenuContainer,
  StyledMenuItem,
  StyledParallax,
  StyledRoot,
  StyledToggleMenuButton,
  StyledWaves,
} from './styles';

interface MenuItem {
  redirect: string;
  label: string;
  role?: string;
  onClick?: () => void;
}

const NewLoggedHeader = (): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { authenticate, logout } = useAuthenticate();

  const handleDownloadSheet = () => {
    window.open(
      `${config.urlServer}/assets/modelo-Milhas-na-Conta.xlsx`,
      '_blank'
    );
  };

  const menuItems: MenuItem[] = [
    { redirect: 'home', label: 'Home' },
    { redirect: 'new-request', label: 'Nova solicitação' },
    {
      redirect: 'csv-model',
      label: 'Baixar planilha modelo',
      onClick: handleDownloadSheet,
    },
    { redirect: 'users', label: 'Usuários', role: 'admin' },
    { redirect: 'extract', label: 'Extratos', role: 'admin' },
    { redirect: 'settings', label: 'Configuração', role: 'admin' },
    { redirect: '', label: 'Sair', onClick: logout },
  ].filter((item) => {
    if (!item.role) {
      return true;
    }

    return item.role === authenticate?.user?.role;
  });

  return (
    <React.Fragment>
      <StyledRoot>
        <StyledHeader>
          <StyledInnerHeader className={isMenuOpen ? 'menu-opened' : ''}>
            <Logo />

            <StyledToggleMenuButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                {isMenuOpen ? (
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.293 5.293a1 1 0 0 1 1.414 0L12 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L10.586 12 5.293 6.707a1 1 0 0 1 0-1.414Z"
                    fill="currentColor"
                  />
                ) : (
                  <path
                    d="M3 6h18M3 12h18M3 18h18"
                    stroke="currentColor"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                )}
              </svg>
              <span>Menu</span>
            </StyledToggleMenuButton>

            <StyledMenuContainer isOpen={isMenuOpen}>
              {menuItems?.map(({ redirect, label, onClick }) => (
                <StyledMenuItem
                  key={redirect}
                  selected={location.pathname === `/${redirect}`}
                  onClick={() => {
                    if (onClick) {
                      onClick();
                    } else {
                      navigate(`/${redirect}`);
                    }
                  }}
                >
                  {label}
                </StyledMenuItem>
              ))}
            </StyledMenuContainer>
          </StyledInnerHeader>

          <div>
            <StyledWaves
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 24 150 28"
              preserveAspectRatio="none"
              shapeRendering="auto"
            >
              <defs>
                <path
                  id="gentle-wave"
                  d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                />
              </defs>
              <StyledParallax>
                <use
                  xlinkHref="#gentle-wave"
                  x="48"
                  y="0"
                  fill="rgba(255,255,255,0.7"
                />
                <use
                  xlinkHref="#gentle-wave"
                  x="48"
                  y="3"
                  fill="rgba(255,255,255,0.5)"
                />
                <use
                  xlinkHref="#gentle-wave"
                  x="48"
                  y="5"
                  fill="rgba(255,255,255,0.3)"
                />
                <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
              </StyledParallax>
            </StyledWaves>
          </div>
        </StyledHeader>
      </StyledRoot>
    </React.Fragment>
  );
};

export default NewLoggedHeader;
