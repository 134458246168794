import * as React from 'react';
import { toast } from 'react-toastify';

import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  codeAccess,
  codeAccessToken,
  resendCodeRequest,
} from '../../api/authenticate';
import useAuthenticate from '../../recoil/hooks/authenticate';
import ClientLoginForm from './ClientLoginForm';
import CodeForm from '../PartnerLogin/CodeForm';

const Login = (): React.ReactElement => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const codeParam = searchParams.get('code');

  const [isLoading, setIsLoading] = React.useState(false);
  const [token, setToken] = React.useState('');

  const { authenticate, signin, logout } = useAuthenticate();

  React.useEffect(() => {
    if (authenticate?.isAuthenticate) {
      if (codeParam && authenticate?.user?.code !== codeParam) {
        logout();
        toast('Para continuar, entre utilizando o código abaixo! ', {
          type: 'info',
        });
        return;
      }

      if (authenticate?.showLandingPage) {
        navigate('/landing-page');
      } else {
        navigate('/home');
      }
    }
  }, [authenticate, codeParam, logout, navigate]);

  const resendCode = async () => {
    try {
      await resendCodeRequest(token);
    } catch (error: any) {
      toast(
        'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
        { type: 'error' }
      );
    }
  };

  const handleSubmitCode = async (code: string) => {
    try {
      setIsLoading(true);

      const data = await codeAccessToken(token, code);

      signin(data.token, data.showLandingPage);
    } catch (error: any) {
      const message = 'Código invalido!';
      toast(message, { type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (body: {
    user: string;
    code: string;
    email: string;
    token: string;
  }) => {
    try {
      setIsLoading(true);

      const data = await codeAccess(body);

      setToken(data.token);
    } catch (error: any) {
      const message = 'Usuário ou código invalido!';
      toast(message, { type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  if (token) {
    return (
      <CodeForm
        handleSubmit={handleSubmitCode}
        isLoading={isLoading}
        resendCode={resendCode}
      />
    );
  }

  return <ClientLoginForm handleSubmit={handleSubmit} isLoading={isLoading} />;
};

export default Login;
