import * as React from 'react';
import { toast } from 'react-toastify';

import { CircularProgress } from '@mui/material';
import SubmitButton from '../../../components/Button';
import SimpleInput from './Input';

import {
  Div,
  Div2,
  Div3,
  Div4,
  Div5,
  Div6,
  Div7,
  BemVindo,
  FormLoginStyled,
  PlaneContainer,
  StyledVersionHeader,
  StyledLink,
  StyledContentLink,
  StyledTimeToResend,
  StyledInfo,
} from './styles';
import Plane from '../../../images/Plane';
import config from '../../../config/config';

interface Props {
  isLoading: boolean;
  handleSubmit: (code: string) => void;
  resendCode: () => void;
}

let intervalTimeToResendCode: NodeJS.Timeout;

const TIME_TO_RESEND_CODE_IN_SECONDS = 60;

const CodeForm = ({
  isLoading,
  handleSubmit,
  resendCode,
}: Props): React.ReactElement => {
  const [formData, setFormData] = React.useState({
    code: '',
  });

  const [timeToResend, setTimeToResend] = React.useState(
    TIME_TO_RESEND_CODE_IN_SECONDS
  );

  React.useEffect(() => {
    clearInterval(intervalTimeToResendCode);

    if (timeToResend > 0) {
      intervalTimeToResendCode = setInterval(() => {
        setTimeToResend((prev) => prev - 1);
      }, 1000);
    } else {
      setTimeToResend(0);
    }

    return () => clearInterval(intervalTimeToResendCode);
  }, [timeToResend]);

  const resendCodeAction = () => {
    setTimeToResend(TIME_TO_RESEND_CODE_IN_SECONDS);
    resendCode();
  };

  const handleSubmitAction = async (event: any) => {
    event.preventDefault();

    const { code } = formData;

    if (!code?.length) {
      toast('Por favor, preencha os campos antes de submeter o formulário.', {
        type: 'error',
      });
      return;
    }

    handleSubmit(code);
  };

  const handleChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Div>
      <StyledVersionHeader>v{config.version}</StyledVersionHeader>
      <Div2>
        <Div3>Milhas na conta</Div3>

        <PlaneContainer>
          <Plane />
        </PlaneContainer>

        <Div4>
          <Div5>
            <BemVindo>Olá milheiro(a)</BemVindo>
          </Div5>

          <Div6>Enviamos um código de acesso para o seu e-mail</Div6>

          <StyledInfo>
            Caso não tenha recebido o e-mail, verifique a caixa de spam
          </StyledInfo>

          <FormLoginStyled>
            <Div7>Código de acesso</Div7>
            <SimpleInput
              placeholder={'Digite o código'}
              name="code"
              onChange={handleChange}
              value={formData.code}
              maxLength={6}
            />

            <StyledContentLink>
              {timeToResend ? (
                <StyledTimeToResend>
                  {timeToResend} segundos para poder reenviar um novo código
                </StyledTimeToResend>
              ) : (
                <StyledLink onClick={resendCodeAction}>
                  Reenviar código
                </StyledLink>
              )}
            </StyledContentLink>

            <SubmitButton onClick={handleSubmitAction} disabled={isLoading}>
              {isLoading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <span>Continuar</span>
              )}
            </SubmitButton>
          </FormLoginStyled>
        </Div4>
      </Div2>
    </Div>
  );
};

export default CodeForm;
