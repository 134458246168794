/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import DoneAllIcon from '@mui/icons-material/DoneAll';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MoreOptions from '../../../components/MoreOptions';
import useAuthenticate from '../../../recoil/hooks/authenticate';
import {
  changeUsercodeStatus,
  getUsercodesByUser,
} from '../../../api/usercodes';
import { formatStatus, formatStatusColor } from './utils';
import { StyledContentCode } from './styles';
import { formatMoney } from '../../../utils/money';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#79cc72',
    color: '#FFF',
    fontWeight: 'bold',
    fontSize: 16,
    fontFamily: 'Poppins,sans-serif',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'Poppins,sans-serif',
  },
}));

const StyledTableRow = styled(TableRow)<{ open?: string }>(
  ({ onClick, open }) => ({
    '&:hover': {
      backgroundColor: '#79cc7222',
      transition: '0.3s',
    },
    transition: '0.5s',
    '&:last-child td, &:last-child th': {
      border: 0,
    },
    backgroundColor: open === 'true' ? '#79cc7222' : '',
    cursor: onClick ? 'pointer' : 'auto',
  })
);

const StyledLink = styled('div')(() => ({
  '&:hover': {
    textDecoration: 'underline',
  },
  transition: '0.5s',
  color: '#00bcd4',
  cursor: 'pointer',
  paddingTop: 10,
}));

const StyledLoading = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: 20,
  boxSizing: 'border-box',
}));

interface Option {
  id: string;
  label: string;
  icon?: React.ReactElement;
  onClick: (id: string) => void;
}

interface RowProps {
  row: any;
  rows: string[];
  options?: Option[];
  handleDownload: (
    requestId: string,
    attachmentId: string,
    filename: string
  ) => void;
  handleSelectUserToAddCode: (id: string) => void;
  handleSelectCodeToEdit: (id: string) => void;
}

interface UserCodesProps {
  userId: string;
  handleSelectUserToAddCode: (id: string) => void;
  handleSelectCodeToEdit: (id: string) => void;
}

function UserCodes({
  userId,
  handleSelectUserToAddCode,
  handleSelectCodeToEdit,
}: UserCodesProps) {
  const [isLoading, setLoading] = React.useState(true);
  const { authenticate } = useAuthenticate();

  const navigate = useNavigate();

  const [codes, setCodes] = React.useState([]);

  const getUsercodes = React.useCallback(
    async (userId: string) => {
      try {
        setLoading(true);
        const data = await getUsercodesByUser(authenticate.token, userId);

        setCodes(data.usercodes);
      } catch (error) {
        toast(
          'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
          {
            type: 'error',
          }
        );
      } finally {
        setLoading(false);
      }
    },
    [authenticate.token]
  );

  const handleChangeStatusCode = async (userId: string, codeId: string) => {
    try {
      await changeUsercodeStatus(userId, authenticate.token, codeId);

      getUsercodes(userId);
    } catch (error) {
      toast(
        'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    }
  };

  React.useEffect(() => {
    if (userId) {
      getUsercodes(userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const [codeCopied, setCodeCopied] = React.useState('');

  if (isLoading) {
    return (
      <StyledLoading>
        <CircularProgress />
      </StyledLoading>
    );
  }

  if (!isLoading && !codes.length) {
    return (
      <StyledLoading>
        <p>Nenhum código adicionado para o usuário</p>
        <StyledLink onClick={() => handleSelectUserToAddCode(userId)}>
          Adicionar
        </StyledLink>
      </StyledLoading>
    );
  }

  const handleCopyCode = (code: string) => {
    navigator.clipboard.writeText(code);

    setCodeCopied(code);

    setTimeout(() => {
      setCodeCopied('');
    }, 1000);
  };

  const redirectToCampaign = (title: string) => {
    navigate(`/settings?tab=1&status=all&search=${title}`);
  };

  return (
    <Table size="small" aria-label="purchases">
      <TableHead>
        <TableRow>
          <TableCell>Código</TableCell>
          <TableCell>Campanha</TableCell>
          <TableCell>Descrição</TableCell>
          <TableCell>Preço do parceiro</TableCell>
          <TableCell>Preço do cliente</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Ações</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {codes.map((code: any) => (
          <TableRow key={code._id}>
            <TableCell>
              <StyledContentCode onClick={() => handleCopyCode(code.code)}>
                {code.code}
                <div>
                  {codeCopied === code.code ? (
                    <DoneAllIcon fontSize="small" htmlColor="green" />
                  ) : (
                    <ContentCopyIcon fontSize="small" />
                  )}
                </div>
              </StyledContentCode>
            </TableCell>

            {code.campaign?.title ? (
              <TableCell
                onClick={() => redirectToCampaign(code.campaign?.title)}
              >
                <StyledLink>{code.campaign?.title}</StyledLink>
              </TableCell>
            ) : (
              <TableCell>-</TableCell>
            )}
            <TableCell>{code.campaign?.description || '-'}</TableCell>
            <TableCell>{formatMoney(code.partnerValue || 0)}</TableCell>
            <TableCell>{formatMoney(code.valueMiles || 0)}</TableCell>
            <TableCell>
              <span style={{ color: formatStatusColor(code.status) }}>
                {formatStatus(code.status)}
              </span>
            </TableCell>
            <TableCell onClick={() => handleChangeStatusCode(userId, code._id)}>
              <StyledLink>
                {code.status === 'active' ? 'Desativar' : 'Ativar'}
              </StyledLink>
            </TableCell>
            <TableCell>
              <MoreOptions
                id={code._id}
                options={[
                  {
                    id: code._id,
                    label: 'Editar',
                    onClick: (id) => handleSelectCodeToEdit(id),
                    disabled: code.status !== 'active',
                  },
                ]}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function Row(props: RowProps) {
  const {
    row,
    rows,
    options,
    handleSelectUserToAddCode,
    handleSelectCodeToEdit,
  } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <StyledTableRow onClick={() => setOpen(!open)} open={String(open)}>
        {rows.map((rowKey) => (
          <StyledTableCell key={rowKey} align="center">
            {row[rowKey]}
          </StyledTableCell>
        ))}
        {options && (
          <StyledTableCell align="center">
            <MoreOptions options={options} id={row.id} />
          </StyledTableCell>
        )}
        <StyledTableCell>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Códigos
              </Typography>
              <UserCodes
                userId={row.id}
                handleSelectUserToAddCode={handleSelectUserToAddCode}
                handleSelectCodeToEdit={handleSelectCodeToEdit}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

interface Props {
  head: string[];
  rows: string[];
  data: any[];
  options?: Option[];
  handleDownload: (
    requestId: string,
    attachmentId: string,
    filename: string
  ) => void;
  handleSelectUserToAddCode: (id: string) => void;
  handleSelectCodeToEdit: (id: string) => void;
}

export const UserTableCollapse = ({
  head,
  rows,
  options,
  data,
  handleDownload,
  handleSelectUserToAddCode,
  handleSelectCodeToEdit,
}: Props): React.ReactElement => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            {head.map((item) => (
              <StyledTableCell align="center" key={item}>
                {item}
              </StyledTableCell>
            ))}
            {options && <StyledTableCell align="center">Ações</StyledTableCell>}
            <StyledTableCell align="center"></StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map((item) => (
            <Row
              key={item.id}
              row={item}
              rows={rows}
              options={options}
              handleDownload={handleDownload}
              handleSelectUserToAddCode={handleSelectUserToAddCode}
              handleSelectCodeToEdit={handleSelectCodeToEdit}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserTableCollapse;
