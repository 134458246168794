import * as React from 'react';

import AddIcon from '@mui/icons-material/Add';
import { useFormik } from 'formik';

import NewInput from './Input';
import {
  StyledForm,
  StyledFormButtons,
  StyledFormInput,
  StyledFormInputs,
} from './styles';
import { ButtonWithIcon } from './Button';
import { FormProps, initialFormValue, validationSchema } from './schema';
import { cpfMask, isCpfValid, removeMask } from '../../../../utils/cpf';
import { formatPhoneNumber } from '../../../../utils/mask';
import { formatNumber } from '../../../../utils/number';
import { formatMoney, moneyMask } from '../../../../utils/money';
import useAuthenticate from '../../../../recoil/hooks/authenticate';
import { autocompleteCpfByUser } from '../../../../api/requestV2';

interface Props {
  isLoading: boolean;
  resetForm?: boolean;
  hidden: boolean;
  pointsValue?: number;
  allowEditValue?: boolean;
  pointsMinimum?: number;
  pointsMaximum?: number;
  handleSubmit: (values: FormProps) => void;
}

const FormAccounts: React.FC<Props> = ({
  isLoading,
  resetForm,
  hidden,
  pointsValue,
  allowEditValue,
  pointsMinimum,
  pointsMaximum,
  handleSubmit,
}): React.ReactElement => {
  const { authenticate } = useAuthenticate();

  const formik = useFormik({
    initialValues: {
      ...initialFormValue,
      valueMile: pointsValue
        ? moneyMask(pointsValue * 100)
        : initialFormValue.valueMile,

      miles: pointsMaximum
        ? formatNumber(pointsMaximum)
        : initialFormValue.miles,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: () => {
      const { values } = formik;

      const points = Number(removeMask(values.miles));

      const value = Number(removeMask(values.valueMile)) / 100;

      let hasError = false;

      if (!allowEditValue && pointsValue && value !== pointsValue) {
        formik.setFieldError(
          'valueMile',
          `O valor deve ser: ${formatMoney(pointsValue)}`
        );
        hasError = true;
      }

      if (pointsMinimum && points < pointsMinimum) {
        formik.setFieldError(
          'miles',
          `Valor mínimo: ${formatNumber(pointsMinimum)}`
        );
        hasError = true;
      }

      if (pointsMaximum && points > pointsMaximum) {
        formik.setFieldError(
          'miles',
          `Valor máximo: ${formatNumber(pointsMaximum)}`
        );
        hasError = true;
      }

      if (hasError) {
        return;
      }

      handleSubmit({
        ...values,
        identification: removeMask(values.identification),
        phone: removeMask(values.phone),
        valueMile: value,
        miles: points,
      });
    },
  });

  React.useEffect(() => {
    if (resetForm) {
      formik.resetForm();
    }
  }, [formik, resetForm]);

  const handleChangeIdentification = (event: any): void => {
    const value = cpfMask(event.target.value);

    formik.setFieldValue('identification', value);
  };

  const handleChangePhone = (event: any): void => {
    const value = formatPhoneNumber(event.target.value);

    formik.setFieldValue('phone', value);
  };

  const handleChangeValue = (event: any): void => {
    const value = moneyMask(event.target.value);

    formik.setFieldValue('valueMile', value);
  };

  const handleChangeMiles = (event: any): void => {
    const value = formatNumber(event.target.value);

    formik.setFieldValue('miles', value);
  };

  const handleChangeEmail = (event: any): void => {
    const value = event.target.value.toUpperCase();

    formik.setFieldValue('email', value);
  };

  const handleChangeName = (event: any): void => {
    const value = event.target.value.toUpperCase();

    formik.setFieldValue('name', value);
  };

  const handleBlurCpf = async (cpf: string): Promise<void> => {
    if (cpf && isCpfValid(cpf)) {
      const cpfValue = removeMask(cpf);

      try {
        const result = await autocompleteCpfByUser(
          cpfValue,
          authenticate.token
        );

        if (result?.account) {
          formik.setFieldValue('name', result?.account?.name || '');
          formik.setFieldValue('email', result?.account?.email || '');
          formik.setFieldValue(
            'phone',
            result?.account?.phone
              ? formatPhoneNumber(result?.account?.phone)
              : ''
          );
        }
        // eslint-disable-next-line no-empty
      } catch {}
    }
  };

  return (
    <React.Fragment>
      <StyledForm onSubmit={formik.handleSubmit} hidden={hidden}>
        <StyledFormInputs>
          <StyledFormInput>
            <NewInput
              name="identification"
              label="CPF do titular *"
              value={formik.values.identification}
              onChange={handleChangeIdentification}
              onBlur={(e) => {
                formik.handleBlur(e);
                handleBlurCpf(e.target.value);
              }}
              error={
                formik.touched.identification
                  ? formik.errors.identification
                  : ''
              }
            />
          </StyledFormInput>
          <StyledFormInput>
            <NewInput
              placeholder="(   ) _ ____-____"
              name="phone"
              label="Telefone do titular *"
              value={formik.values.phone}
              onChange={handleChangePhone}
              onBlur={formik.handleBlur}
              error={formik.touched.phone ? formik.errors.phone : ''}
            />
          </StyledFormInput>
        </StyledFormInputs>
        <StyledFormInput>
          <NewInput
            placeholder="Nome completo do titular"
            name="name"
            label="Nome do titular *"
            value={formik.values.name}
            onChange={handleChangeName}
            onBlur={formik.handleBlur}
            error={formik.touched.name ? formik.errors.name : ''}
          />
        </StyledFormInput>
        <StyledFormInput>
          <NewInput
            placeholder="E-mail cadastrado no programa"
            name="email"
            label="E-mail do titular *"
            value={formik.values.email}
            onChange={handleChangeEmail}
            onBlur={formik.handleBlur}
            error={formik.touched.email ? formik.errors.email : ''}
          />
        </StyledFormInput>
        <StyledFormInputs>
          <StyledFormInput>
            <NewInput
              placeholder="XX.XXX"
              name="miles"
              label="Total de pontos *"
              value={formik.values.miles}
              onChange={handleChangeMiles}
              onBlur={formik.handleBlur}
              min={pointsMinimum}
              max={pointsMaximum}
              error={formik.touched.miles ? formik.errors.miles : ''}
            />
          </StyledFormInput>
          <StyledFormInput>
            <NewInput
              placeholder="R$ XX.XX,XX"
              name="valueMile"
              label="Valor de 1.000 pontos *"
              value={formik.values.valueMile}
              onChange={handleChangeValue}
              disabled={!allowEditValue}
              onBlur={formik.handleBlur}
              error={formik.touched.valueMile ? formik.errors.valueMile : ''}
            />
          </StyledFormInput>
        </StyledFormInputs>

        <StyledFormButtons>
          <ButtonWithIcon
            label="Adicionar conta"
            icon={<AddIcon />}
            isLoading={isLoading}
            type="submit"
          />
        </StyledFormButtons>
      </StyledForm>
    </React.Fragment>
  );
};

export default FormAccounts;
