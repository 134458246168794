import React, { FC, ReactElement } from 'react';
import { Typography } from '@mui/material';

import PaidIcon from '@mui/icons-material/Paid';
import CommentIcon from '@mui/icons-material/Comment';
import PixIcon from '@mui/icons-material/Pix';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

import {
  StyledCardRequest,
  StyledContentCard,
  StyledContentCardInfo,
  StyledContentPaymentField,
} from './styles';

import { formatShortDate } from '../../utils/date';
import { formatNumber } from '../../utils/number';
import { formatMoney } from '../../utils/money';
import {
  requestStatusColor,
  requestStatusLabel,
} from '../../pages/HomeV2/utils';
import useAuthenticate from '../../recoil/hooks/authenticate';

interface Props {
  item: any;
  hiddenComments?: boolean;
  onClick?: () => void;
}

const CardRequest: FC<Props> = ({
  item,
  onClick,
  hiddenComments,
}): ReactElement => {
  const { authenticate } = useAuthenticate();

  const isAdmin = React.useMemo(
    () => ['admin'].includes(authenticate?.user?.role),
    [authenticate]
  );

  const isPartner = React.useMemo(
    () => ['partner'].includes(authenticate?.user?.role),
    [authenticate]
  );

  const formatValue = (item: any): React.ReactElement | string => {
    if (isAdmin) {
      if (item.total.toFixed(2) === item.paid.toFixed(2)) {
        return (
          <StyledContentPaymentField>
            <div>{formatMoney(item.total)}</div>
            <PaidIcon htmlColor="green" />
          </StyledContentPaymentField>
        );
      }

      return (
        <StyledContentPaymentField>
          <div>{`${formatMoney(item.paid || '0')} / ${formatMoney(
            item.total || '0'
          )}`}</div>
          <WarningRoundedIcon htmlColor="orange" />
        </StyledContentPaymentField>
      );
    }

    return formatMoney(item.total);
  };

  return (
    <StyledCardRequest onClick={onClick}>
      <div
        style={{
          paddingBottom: '4px',
          borderBottom: '1px solid #79cc7244',
          marginBottom: '8px',
        }}
      >
        <Typography component="p" fontSize={16} fontWeight="bold">
          {item.code}
        </Typography>
      </div>
      <StyledContentCard>
        <StyledContentCardInfo>
          <div>
            <div>ID:</div> {item.id.substring(17, item.id.length)}
          </div>
          <div>
            <div>Data:</div> {formatShortDate(item.date).replace(',', '')}
          </div>
          <div>
            <div>Pontos:</div> {formatNumber(item.miles || '')}
          </div>
          <div>
            <div>Valor: </div>
            {formatValue(item)}
          </div>

          {(isAdmin || isPartner) && (
            <div>
              <div>Usuário: </div>
              {item.user}
            </div>
          )}

          {isAdmin && (
            <div>
              <div>Parceiro: </div>
              {item.partner}
            </div>
          )}
        </StyledContentCardInfo>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingTop: 8,
            gap: 8,
            alignItems: 'flex-end',
          }}
        >
          <div
            style={{
              fontSize: 14,
              fontWeight: 500,
              background: requestStatusColor(item.status),
              color: '#fff',
              padding: 4,
              boxSizing: 'border-box',
              width: 150,
              textAlign: 'center',
              borderRadius: 4,
            }}
          >
            {requestStatusLabel(item.status)}
          </div>
          {Boolean(item.comments?.length) && !hiddenComments && (
            <div>
              <CommentIcon />
            </div>
          )}
          {Boolean(item.pix) && !hiddenComments && (
            <div>
              <PixIcon htmlColor="#77B6A8" />
            </div>
          )}
          {onClick && (
            <div style={{ fontSize: 14, fontWeight: 500 }}>Ver detalhes</div>
          )}
        </div>
      </StyledContentCard>
    </StyledCardRequest>
  );
};

export default CardRequest;
