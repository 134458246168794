import api from '../services/api';

interface Data {
  user: string;
  code: string;
  token: string;
}

export const singin = async (data: Data): Promise<any> => {
  const result = await api.post(
    '/api/signin',
    {
      username: data.user,
      code: data.code,
      recaptchaToken: data.token,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return result?.data;
};

export const singinCode = async (token: string, code: string): Promise<any> => {
  const result = await api.post(
    '/api/signin-code',
    { code },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const resendCodeRequest = async (token: string): Promise<any> => {
  const result = await api.post(
    '/api/resend-code',
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

interface CodeAccessProps {
  user: string;
  code: string;
  email: string;
  token: string;
}

export const codeAccess = async (data: CodeAccessProps): Promise<any> => {
  const result = await api.post(
    '/api/code-access',
    {
      username: data.user,
      code: data.code,
      email: data.email,
      recaptchaToken: data.token,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return result?.data;
};

export const codeAccessToken = async (
  token: string,
  code: string
): Promise<any> => {
  const result = await api.post(
    '/api/code-access-token',
    {
      code,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const handleLogoutUsersRequest = async (token: string): Promise<any> => {
  const result = await api.delete('/api/users-sessions', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export default {
  singin,
  codeAccess,
  handleLogoutUsersRequest,
};
