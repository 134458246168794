import styled from 'styled-components';

import { Box, Typography } from '@mui/material';

export const StyledBox = styled(Box)({
  width: '100%',
  height: 'calc(var(--vh) * 100)',
  position: 'absolute',
  bottom: 0,
  borderRadius: 4,
  color: '#212529',
  background: '#fff',
  padding: 32,
  boxSizing: 'border-box',
  overflowY: 'auto',

  '@media (max-width: 800px)': {
    padding: 16,
  },
});

export const FormLoginStyled = styled.form`
  width: 100%;
  height: 100%;
`;

export const StyledTitle = styled(Typography)({
  fontFamily: 'Inter',
  display: 'flex',
  flexWrap: 'wrap',
  color: '#79cc72',
  fontSize: 18,
  fontWeight: 'bold',
});

export const Div7 = styled.div`
  font-family: Rubik, sans-serif;
  margin-top: 32px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const Div9 = styled.div`
  font-family: Rubik, sans-serif;
  margin-top: 30px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const StyledContentButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;
