import { FormControl, MenuItem, Select } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { StyledError, StyledLabel } from './styles';

const InputContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
`;

const StyledSelect = styled(Select)({
  borderRadius: '4px',
  border: '1px solid #ced4da',
  height: 49,
  justifyContent: 'center',
  alignItems: 'start',
  whiteSpace: 'nowrap',
  backgroundColor: '#fff',
  width: '100%',
  fontSize: '14px',
  fontFamily: 'Poppins, sans-serif',

  '@media (max-width: 991px)': {
    whiteSpace: 'initial',
    maxWidth: '100%',
    paddingRight: '20px',
  },

  '&::placeholder': {
    color: 'rgba(21, 110, 121, 0.5)',
  },
  color: 'rgba(21, 110, 121, 1)',

  '&:disabled': {
    background: '#ccc !important',
    pointerEvents: 'none',
  },

  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #ced4da !important',
  },

  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#000 !important',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#000 !important',
  },
});

interface Option {
  value: string;
  label: string;
}

interface NewSelectProps {
  value?: string;
  onChange?: (event?: any) => void;
  name?: string;
  label?: string;
  options: Option[];
  error?: string;
  disabled?: boolean;
  onBlur?: (event: any) => void;
}

const NewSelect = ({
  value,
  onChange,
  name,
  label,
  options,
  error,
  disabled,
  onBlur,
}: NewSelectProps): any => {
  return (
    <React.Fragment>
      {!!label && <StyledLabel>{label}</StyledLabel>}

      <InputContainer>
        <FormControl fullWidth>
          <StyledSelect
            name={name}
            variant="outlined"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
          >
            {options?.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </StyledSelect>
          {!!error && <StyledError>{error}</StyledError>}
        </FormControl>
      </InputContainer>
    </React.Fragment>
  );
};

export default NewSelect;
