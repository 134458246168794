/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Lottie from 'react-lottie';
import {
  Div2,
  Div,
  StyledRequestsContainer,
  StyleContentTitleContainer,
  StyledAreaContainer,
  StyledPlaneContainer,
  StyledSpanContent,
  StyledContentContainer,
  StyledRequestsContainerTable,
  StyledLoading,
  StyledContentSearch,
} from './styles';

import Plane from '../../images/Plane';
import { getAllRequest } from '../../api/request';
import useAuthenticate from '../../recoil/hooks/authenticate';
import NewLoggedHeader from '../../components/NewLoggedHeader';
import animationData from './loading.json';
import SearchInput from '../../components/SearchInput';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const AllRequest = (): React.ReactElement => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(true);

  const { authenticate } = useAuthenticate();

  const [data, setData] = React.useState([]);

  const getData = React.useCallback(
    async (search?: string) => {
      try {
        setIsLoading(true);
        const data = await getAllRequest(authenticate.token, search);

        setData(data.data);
      } catch (error) {
        toast(
          'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
          {
            type: 'error',
          }
        );
      } finally {
        setIsLoading(false);
      }
    },
    [authenticate.token]
  );

  React.useEffect(() => {
    if (authenticate.isAuthenticate) {
      getData();
    }
  }, [authenticate.isAuthenticate, getData]);

  const handleSearch = (search: string): void => {
    getData(search);
  };

  return (
    <Div>
      <Div2>
        <NewLoggedHeader />

        <StyledContentSearch>
          <SearchInput handleSubmit={handleSearch} />
        </StyledContentSearch>

        {isLoading && (
          <StyledLoading>
            <Lottie options={defaultOptions} height={150} width={150} />
            <div>Carregando</div>
          </StyledLoading>
        )}

        {!isLoading && !data.length && (
          <StyledRequestsContainer>
            <StyleContentTitleContainer>
              <span>Você ainda não tem solicitações :(</span>
            </StyleContentTitleContainer>
            <StyledContentContainer>
              <StyledAreaContainer
                onClick={() => {
                  navigate('/new-request');
                }}
              >
                <StyledPlaneContainer>
                  <Plane />
                </StyledPlaneContainer>
                <StyledSpanContent>
                  <strong>
                    <u>Clique aqui</u>
                  </strong>{' '}
                  para fazer sua primeira solicitação.
                </StyledSpanContent>
              </StyledAreaContainer>
            </StyledContentContainer>
          </StyledRequestsContainer>
        )}

        {!isLoading && !!data.length && (
          <StyledRequestsContainerTable></StyledRequestsContainerTable>
        )}
      </Div2>
    </Div>
  );
};

export default AllRequest;
