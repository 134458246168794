import api from '../services/api';

export const getUsercodesByUser = async (
  token: string,
  userId: string
): Promise<any> => {
  const result = await api.get(`/api/users/${userId}/usercodes`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

interface CreateCodeData {
  campaign: string;
  code: string;
}

export const createCode = async (
  userId: string,
  data: CreateCodeData,
  token: string
): Promise<any> => {
  const result = await api.post(`/api/users/${userId}/usercodes`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const changeUsercodeStatus = async (
  userId: string,
  token: string,
  codeId: string
): Promise<any> => {
  const result = await api.patch(
    `/api/users/${userId}/usercodes/${codeId}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

interface UsercodeEditData {
  partnerValue: number;
  valueMiles: number;
}

export const editCode = async (
  userId: string,
  data: UsercodeEditData,
  token: string
): Promise<any> => {
  const result = await api.patch(`/api/usercode/${userId}`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export default {
  getUsercodesByUser,
  createCode,
};
