/* eslint-disable no-underscore-dangle */
import AddIcon from '@mui/icons-material/Add';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import TimelineIcon from '@mui/icons-material/Timeline';
import * as React from 'react';
import Lottie from 'react-lottie';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { TablePagination } from '@mui/material';
import {
  Div,
  Div2,
  StyleContentTitleContainer,
  StyledAreaContainer,
  StyledContentButtonsHeader,
  StyledContentContainer,
  StyledContentFilterStatus,
  StyledHeaderTitlePage,
  StyledLoading,
  StyledPlaneContainer,
  StyledRequestsContainer,
  StyledRequestsContainerTable,
  StyledSpanContent,
  StyleLinkOldRequests,
  StyleLinkOldRequestsContainer,
} from './styles';

import {
  deleteRequest,
  previewRequestAttachmentByUser,
} from '../../api/request';
import { ButtonWithIcon } from '../../components/Button';

import NewLoggedHeader from '../../components/NewLoggedHeader';
import Plane from '../../images/Plane';
import useAuthenticate from '../../recoil/hooks/authenticate';
import animationData from './loading.json';

import { getAllExtract, vinculateExtract } from '../../api/extract';
import ModalPreviewImage from '../../components/ModalPreviewImage';
import SimpleSelect from '../Login/Select';
import ModalDeleteRequest from './ModalDeleteRequest';
import { RequestStatusEnum, requestStatusLabel } from './utils';
import ModalDetails from './ModalDetails';
import CardRequest from '../../components/CardRequest';
import { getAllRequestByUserV2 } from '../../api/requestV2';
import ModalExportReportRequests from './ModalExportReportRequests';
import { StyledContentPagination } from '../../components/TableCollapse/styles';
import ModalChangeStatusRequest from './ModalChangeStatusRequest';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Home = (): React.ReactElement => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const requestQuery = searchParams.get('requestId');

  const { authenticate } = useAuthenticate();

  const [isLoading, setIsLoading] = React.useState(true);

  const [isLoadingPreviewImage, setLoadingPreviewImage] = React.useState(true);

  const [openModalExport, setOpenModalExport] = React.useState(false);

  const [openModalStatus, setOpenModalStatus] = React.useState(false);

  const [modalDetails, setModalDetails] = React.useState({
    open: false,
    id: '',
  });

  const [modalDeleteRequest, setModalDeleteRequest] = React.useState({
    open: false,
    isLoading: false,
    requestId: '',
  });

  const [modalPreview, setModalPreview] = React.useState({
    open: false,
    fileId: '',
    url: '',
    typeFile: '',
    payments: [],
  });

  const isAdmin = React.useMemo(
    () => ['admin'].includes(authenticate?.user?.role),
    [authenticate]
  );

  const deleteQueryParam = React.useCallback(
    (key: string) => {
      const newParams = new URLSearchParams(searchParams);
      newParams.delete(key);
      navigate(`?${newParams.toString()}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  React.useEffect(() => {
    if (requestQuery) {
      setModalDetails({ open: true, id: requestQuery });

      deleteQueryParam('requestId');
    }
  }, [requestQuery, deleteQueryParam]);

  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(10);

  const [filterStatus, setFilterStatus] = React.useState(
    RequestStatusEnum.open
  );

  const [airlineProgram, setAirlineProgram] = React.useState('all');

  const [data, setData] = React.useState([]);

  const getData = React.useCallback(
    async (
      filterStatus: RequestStatusEnum,
      airlineProgram: string,
      defaultLoading = true
    ) => {
      try {
        setIsLoading(defaultLoading);
        const data = await getAllRequestByUserV2(
          authenticate.token,
          filterStatus,
          airlineProgram,
          page,
          itemsPerPage
        );

        setData(data.data);
        setCount(data.count || 0);
      } catch (error) {
        toast(
          'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
          {
            type: 'error',
          }
        );
      } finally {
        setIsLoading(false);
      }
    },
    [authenticate.token, page, itemsPerPage]
  );

  React.useEffect(() => {
    if (authenticate.isAuthenticate) {
      getData(filterStatus, airlineProgram);
    }
  }, [authenticate.isAuthenticate, filterStatus, airlineProgram, getData]);

  const handlePreviewFile = async (
    fileId: string,
    filename: string,
    payments: any
  ): Promise<void> => {
    try {
      setLoadingPreviewImage(true);

      setModalPreview({
        fileId,
        open: true,
        url: '',
        typeFile: '',
        payments,
      });

      const { urlFile, typeFile } = await previewRequestAttachmentByUser(
        fileId,
        authenticate.token
      );

      setModalPreview({
        fileId,
        open: true,
        url: urlFile,
        typeFile,
        payments,
      });
    } catch (error) {
      toast(
        'Não foi possível visualizar a imagem. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    } finally {
      setLoadingPreviewImage(false);
    }
  };

  const handleSubmitDeleteRequest = async (): Promise<void> => {
    setModalDeleteRequest({ ...modalDeleteRequest, isLoading: true });
    try {
      await deleteRequest(modalDeleteRequest.requestId, authenticate.token);

      setModalDeleteRequest({
        ...modalDeleteRequest,
        open: false,
        isLoading: false,
      });

      toast('Solicitação deletada com sucesso!', {
        type: 'success',
      });

      getData(filterStatus, airlineProgram);
    } catch (error) {
      setModalDeleteRequest({
        ...modalDeleteRequest,
        isLoading: false,
      });
      toast(
        'Não foi possível deletar a solicitação. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    }
  };

  const [dataExtract, setDataExtract] = React.useState([]);

  const getDataExtract = React.useCallback(
    async (search?: string) => {
      try {
        const data = await getAllExtract(authenticate.token, search, 'active');

        setDataExtract(data.data);
      } catch (error) {
        toast(
          'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
          {
            type: 'error',
          }
        );
      }
    },
    [authenticate.token]
  );

  React.useEffect(() => {
    if (authenticate?.isAuthenticate && authenticate?.user?.role === 'admin') {
      getDataExtract();
    }
  }, [authenticate, getDataExtract]);

  const handleSelectExtract = async (id: string) => {
    try {
      await vinculateExtract(authenticate.token, modalPreview.fileId, id);

      getDataExtract();

      toast('Comprovante vinculado com sucesso!', {
        type: 'success',
      });
    } catch (error) {
      toast(
        'Não foi possível realizar essa ação. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    }
  };

  return (
    <React.Fragment>
      <Div>
        <ModalPreviewImage
          open={modalPreview.open}
          fileId={modalPreview.fileId}
          url={modalPreview.url}
          typeFile={modalPreview.typeFile}
          payments={modalPreview.payments}
          isLoading={isLoadingPreviewImage}
          handleClose={() => setModalPreview({ ...modalPreview, open: false })}
          handlePreviewFile={handlePreviewFile}
          handleSelectExtract={handleSelectExtract}
          extracts={dataExtract}
        />
        <ModalExportReportRequests
          open={openModalExport}
          handleClose={() => setOpenModalExport(false)}
        />

        <ModalChangeStatusRequest
          open={openModalStatus}
          handleClose={() => setOpenModalStatus(false)}
          getData={() => getData(filterStatus, airlineProgram, false)}
        />

        <ModalDeleteRequest
          open={modalDeleteRequest.open}
          isLoading={modalDeleteRequest.isLoading}
          handleClose={() =>
            setModalDeleteRequest({ ...modalDeleteRequest, open: false })
          }
          handleSubmit={handleSubmitDeleteRequest}
        />

        <Div2>
          {/* <LoggedHeader /> */}
          <NewLoggedHeader />

          {/* <BannerPix /> */}

          <StyledHeaderTitlePage>
            <h1>Histórico de solicitações</h1>
            <StyledContentButtonsHeader>
              {authenticate?.user?.role === 'admin' && (
                <React.Fragment>
                  <StyledContentFilterStatus>
                    <SimpleSelect
                      name="airlineProgram"
                      value={airlineProgram}
                      onChange={(event) => {
                        setPage(0);
                        setAirlineProgram(event.target.value);
                      }}
                      options={[
                        {
                          label: 'Todos',
                          value: 'all',
                        },
                        {
                          label: 'Azul',
                          value: 'Azul',
                        },
                        {
                          label: 'Smiles',
                          value: 'Smiles',
                        },
                        {
                          label: 'Latam',
                          value: 'Latam',
                        },
                        {
                          label: 'Iberia',
                          value: 'Iberia',
                        },
                        {
                          label: 'Outros',
                          value: 'Outros',
                        },
                      ]}
                    />
                  </StyledContentFilterStatus>
                  <StyledContentFilterStatus>
                    <SimpleSelect
                      name="status"
                      value={filterStatus}
                      onChange={(event) => {
                        setPage(0);
                        setFilterStatus(event.target.value);
                      }}
                      options={[
                        {
                          value: RequestStatusEnum.all,
                          label: requestStatusLabel(RequestStatusEnum.all),
                        },
                        {
                          value: RequestStatusEnum.open,
                          label: requestStatusLabel(RequestStatusEnum.open),
                        },
                        {
                          value: RequestStatusEnum.pending,
                          label: requestStatusLabel(RequestStatusEnum.pending),
                        },
                        {
                          value: RequestStatusEnum.analysing,
                          label: requestStatusLabel(
                            RequestStatusEnum.analysing
                          ),
                        },
                        {
                          value: RequestStatusEnum.confirmed,
                          label: requestStatusLabel(
                            RequestStatusEnum.confirmed
                          ),
                        },
                        {
                          value: RequestStatusEnum.waitingCredit,
                          label: requestStatusLabel(
                            RequestStatusEnum.waitingCredit
                          ),
                        },
                        {
                          value: RequestStatusEnum.completed,
                          label: requestStatusLabel(
                            RequestStatusEnum.completed
                          ),
                        },
                        {
                          value: RequestStatusEnum.canceled,
                          label: requestStatusLabel(RequestStatusEnum.canceled),
                        },
                      ]}
                    />
                  </StyledContentFilterStatus>

                  <ButtonWithIcon
                    label="Mudar status"
                    icon={<TimelineIcon />}
                    onClick={() => setOpenModalStatus(true)}
                  />

                  <ButtonWithIcon
                    label="Exportar"
                    icon={<FileDownloadOutlinedIcon />}
                    onClick={() => setOpenModalExport(true)}
                  />
                </React.Fragment>
              )}
              <ButtonWithIcon
                label="Nova solicitação"
                icon={<AddIcon />}
                onClick={() => navigate('/new-request')}
              />
            </StyledContentButtonsHeader>
          </StyledHeaderTitlePage>

          {isAdmin && (
            <StyleLinkOldRequestsContainer>
              <StyleLinkOldRequests onClick={() => navigate('/home-old')}>
                Ver solicitações antigas
              </StyleLinkOldRequests>
            </StyleLinkOldRequestsContainer>
          )}

          {isLoading && (
            <StyledLoading>
              <Lottie options={defaultOptions} height={150} width={150} />
              <div>Carregando</div>
            </StyledLoading>
          )}

          {!isLoading && !data.length && (
            <StyledRequestsContainer>
              <StyleContentTitleContainer>
                <span>Você ainda não tem solicitações :(</span>
              </StyleContentTitleContainer>
              <StyledContentContainer>
                <StyledAreaContainer
                  onClick={() => {
                    navigate('/new-request');
                  }}
                >
                  <StyledPlaneContainer>
                    <Plane />
                  </StyledPlaneContainer>
                  <StyledSpanContent>
                    <strong>
                      <u>Clique aqui</u>
                    </strong>{' '}
                    para fazer sua primeira solicitação.
                  </StyledSpanContent>
                </StyledAreaContainer>
              </StyledContentContainer>
            </StyledRequestsContainer>
          )}

          {!isLoading && !!data.length && (
            <StyledRequestsContainerTable className="table-custom">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 16,
                  boxSizing: 'border-box',
                }}
              >
                {data.map((item: any) => (
                  <CardRequest
                    item={item}
                    onClick={() => {
                      setModalDetails({ open: true, id: item.id });
                    }}
                    key={item.id}
                  />
                ))}
              </div>
              <StyledContentPagination>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  labelRowsPerPage="Itens por página"
                  labelDisplayedRows={(item) =>
                    `${item.from} - ${item.to} de ${item.count}`
                  }
                  component="div"
                  count={count}
                  rowsPerPage={itemsPerPage}
                  page={page}
                  onPageChange={(e, page) => setPage(page)}
                  onRowsPerPageChange={(event) =>
                    setItemsPerPage(Number(event.target.value))
                  }
                />
              </StyledContentPagination>
            </StyledRequestsContainerTable>
          )}
          <ModalDetails
            id={modalDetails.id}
            open={modalDetails.open}
            onClose={() => setModalDetails({ open: false, id: '' })}
            updateData={() => getData(filterStatus, airlineProgram)}
          />
        </Div2>
      </Div>
    </React.Fragment>
  );
};

export default Home;
