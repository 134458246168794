import * as React from 'react';

import ClearIcon from '@mui/icons-material/Clear';

import { IconButton } from '@mui/material';

import {
  Div20,
  Div25,
  Div28,
  StyledContentFiles,
  StyledContentFilesItem,
  GreenSquare,
} from './styles';

import AttachmentButton from '../../../../components/AttachmentButton';

interface AttachmentFile {
  id: string;
  name: string;
}

interface Props {
  isLoading: boolean;
  paymentFiles: AttachmentFile[];

  handleUploadPaymentFile: (files: FileList) => void;
  removePaymentItem: (id: string) => void;
}

const AttachmentPayment: React.FC<Props> = ({
  isLoading,
  paymentFiles,
  handleUploadPaymentFile,
  removePaymentItem,
}): React.ReactElement => {
  return (
    <React.Fragment>
      <Div25>
        <Div20>
          Anexe um ou mais comprovantes de pagamento de até 10Mb por arquivo.
        </Div20>
        <AttachmentButton
          label="Anexar comprovante*"
          disabled={isLoading}
          handleChange={handleUploadPaymentFile}
          acceptTypes="image/*,.pdf"
          multiple
        />
        {paymentFiles?.length ? (
          <Div28>
            {paymentFiles?.length > 1
              ? `${paymentFiles.length} arquivos adicionados`
              : '1 arquivo adicionado'}
          </Div28>
        ) : (
          <Div28>Nenhum arquivo adicionado</Div28>
        )}
        <StyledContentFiles>
          {paymentFiles.map((file) => (
            <StyledContentFilesItem key={file.id}>
              <div>{file.name}</div>
              <IconButton onClick={() => removePaymentItem(file.id)}>
                <ClearIcon fontSize="small" color="error" />
              </IconButton>
            </StyledContentFilesItem>
          ))}
        </StyledContentFiles>
      </Div25>
      <GreenSquare>
        RP Travel & Tech • Chave Pix: 54.264.159/0001-42 (cnpj)
      </GreenSquare>
    </React.Fragment>
  );
};

export default AttachmentPayment;
