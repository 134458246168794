import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { StyleBox, StyleContent, StyleContentButton } from './styles';
import SubmitButton, { Button } from '../Button';

interface Props {
  open: boolean;
  title: string;
  description: string;
  onClose: () => void;
  onConfirm: () => void;
}

export default function ModalConfirm({
  open,
  title,
  description,
  onClose,
  onConfirm,
}: Props): React.ReactElement {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <StyleBox>
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            color="primary"
          >
            {title}
          </Typography>
          <StyleContent>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {description}
            </Typography>
          </StyleContent>
          <StyleContentButton>
            <Button onClick={onClose}>
              <span>Cancelar</span>
            </Button>
            <SubmitButton onClick={onConfirm}>
              <span>Confirmar</span>
            </SubmitButton>
          </StyleContentButton>
        </StyleBox>
      </Fade>
    </Modal>
  );
}
