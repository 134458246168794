/* eslint-disable no-underscore-dangle */
import * as React from 'react';

import { toast } from 'react-toastify';
import Lottie from 'react-lottie';
import ArchiveIcon from '@mui/icons-material/Archive';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';

import {
  Div2,
  Div,
  StyledRequestsContainer,
  StyleContentTitleContainer,
  StyledAreaContainer,
  StyledPlaneContainer,
  StyledSpanContent,
  StyledContentContainer,
  StyledRequestsContainerTable,
  StyledLoading,
  StyledContentSearch,
  StyledContentFilterStatus,
} from './styles';

import Plane from '../../images/Plane';
import useAuthenticate from '../../recoil/hooks/authenticate';
import { formatShortDate } from '../../utils/date';
import NewLoggedHeader from '../../components/NewLoggedHeader';
import animationData from './loading.json';

import SearchInput from '../../components/SearchInput';
import { ButtonWithIcon } from '../../components/Button';
import ModalImportExtract from './ModalImportExtract';
import ExtractTableCollapse from './ExtractTableCollapse';

import {
  archiveExtract,
  // autoImportExtract,
  getAllExtract,
} from '../../api/extract';
import { formatMoney } from '../../utils/money';
import {
  getRequestIdByPayment,
  getRequestIdByPix,
  previewRequestAttachmentByUser,
} from '../../api/request';
import ModalPreviewImage from '../../components/ModalPreviewImage';
import SimpleSelect from '../Login/Select';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Extract = (): React.ReactElement => {
  const [isLoading, setIsLoading] = React.useState(true);

  const [isCreating, setIsCreating] = React.useState(false);

  const [filterStatus, setFilterStatus] = React.useState('active');

  const [search, setSearch] = React.useState('');

  const { authenticate } = useAuthenticate();

  const [data, setData] = React.useState([]);

  const [isLoadingPreviewImage, setLoadingPreviewImage] = React.useState(true);

  const [modalPreview, setModalPreview] = React.useState({
    open: false,
    fileId: '',
    url: '',
    typeFile: '',
    payments: [],
  });

  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(10);

  const getData = React.useCallback(
    async (
      search?: string,
      status?: string,
      page?: number,
      itemsPerPage?: number
    ) => {
      try {
        setIsLoading(true);
        const data = await getAllExtract(
          authenticate.token,
          search,
          status,
          page,
          itemsPerPage
        );

        setData(data.data);
        setCount(data.count || 0);
      } catch (error) {
        toast(
          'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
          {
            type: 'error',
          }
        );
      } finally {
        setIsLoading(false);
      }
    },
    [authenticate.token]
  );

  React.useEffect(() => {
    if (authenticate.isAuthenticate) {
      getData(search, filterStatus, page, itemsPerPage);
    }
  }, [
    authenticate.isAuthenticate,
    filterStatus,
    getData,
    search,
    page,
    itemsPerPage,
  ]);

  const handleSearch = (search: string): void => {
    setSearch(search);
  };

  const prepareDescription = (text: string): string => {
    if (!text) {
      return '-';
    }

    if (text?.length < 30) {
      return text;
    }

    return `${text.substring(0, 30)}...`;
  };

  const formatSendDate = (date: string): string => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1);

    return formatShortDate(newDate);
  };

  const prepareData = (data: any) => {
    return data.map((item: any) => ({
      id: item._id,
      shortId: item._id.substring(17, item._id.length),
      accountNumber: item.accountNumber,
      agencyNumber: item.agencyNumber,
      clientName: item.clientName,
      transactionAmount: formatMoney(item.transactionAmount || '0'),
      transactionIdentifier: item.transactionIdentifier.slice(-7),
      transactionDateSent: formatSendDate(item.transactionDateSent),
      transactionDescription: prepareDescription(item.transactionDescription),
      payerIdentify: item.payerIdentify,
      payerName: item.payerName || item.payerCompanyName,
      attachment: '-',
      recordType: item.recordType,
      paymentReceiptId: item.paymentReceiptId,
      pixId: item.pixId,
      archived: item.archived,
      status: item.archived ? 'Arquivado' : 'Ativo',
    }));
  };

  const handleSubmitImportExtract = async (): Promise<void> => {
    setIsCreating(false);

    getData(search, filterStatus, page, itemsPerPage);
  };

  const handleRedirectToRequest = async (paymentId: string): Promise<void> => {
    try {
      const result = await getRequestIdByPayment(paymentId, authenticate.token);

      if (result.requestId) {
        window.open(`/#/home?requestId=${result.requestId}`, '_blank');
        return;
      }

      throw Error;
    } catch {
      toast(
        'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    }
  };

  const handleRedirectPixToRequest = async (pixId: string): Promise<void> => {
    try {
      const result = await getRequestIdByPix(pixId, authenticate.token);

      if (result.requestId) {
        window.open(`/#/home?requestId=${result.requestId}`, '_blank');
        return;
      }

      throw Error;
    } catch {
      toast(
        'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    }
  };

  // const handleAutoImport = async () => {
  //   try {
  //     await autoImportExtract(authenticate.token);

  //     toast('Importação realizada com sucesso!', {
  //       type: 'success',
  //     });
  //   } catch (error) {
  //     console.log('error', error);
  //     toast(
  //       'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
  //       {
  //         type: 'error',
  //       }
  //     );
  //   }
  // };

  const handlePreviewFile = async (fileId: string): Promise<void> => {
    try {
      setLoadingPreviewImage(true);

      setModalPreview({
        fileId,
        open: true,
        url: '',
        typeFile: '',
        payments: [],
      });

      const { urlFile, typeFile } = await previewRequestAttachmentByUser(
        fileId,
        authenticate.token
      );

      setModalPreview({
        fileId,
        open: true,
        url: urlFile,
        typeFile,
        payments: [],
      });
    } catch (error) {
      toast(
        'Não foi possível visualizar a imagem. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    } finally {
      setLoadingPreviewImage(false);
    }
  };

  const handleArchiveItem = async (id: string) => {
    try {
      setIsLoading(true);
      await archiveExtract(authenticate.token, id);

      getData(search, filterStatus, page, itemsPerPage);
    } catch (error) {
      toast(
        'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );

      setIsLoading(false);
    }
  };

  return (
    <Div>
      <Div2>
        <NewLoggedHeader />

        <StyledContentSearch>
          <SearchInput handleSubmit={handleSearch} />
          <StyledContentFilterStatus>
            <SimpleSelect
              name="status"
              value={filterStatus}
              onChange={(event) => {
                setPage(0);
                setFilterStatus(event.target.value);
              }}
              options={[
                {
                  value: 'all',
                  label: 'Todos',
                },
                {
                  value: 'active',
                  label: 'Ativo',
                },
                {
                  value: 'archived',
                  label: 'Arquivado',
                },
              ]}
            />
          </StyledContentFilterStatus>

          <ButtonWithIcon
            label="Importar extrato"
            icon={<AccountBalanceOutlinedIcon />}
            onClick={() => setIsCreating(true)}
          />

          {/* <ButtonWithIcon
            label="Importar Auto"
            icon={<AccountBalanceOutlinedIcon />}
            onClick={handleAutoImport}
            color="secondary"
          /> */}
        </StyledContentSearch>

        {isLoading && (
          <StyledLoading>
            <Lottie options={defaultOptions} height={150} width={150} />
            <div>Carregando</div>
          </StyledLoading>
        )}

        {!isLoading && !data.length && (
          <StyledRequestsContainer>
            <StyleContentTitleContainer>
              <span style={{ fontWeight: 'bold' }}>
                Nenhum extrato importado ainda!
              </span>
            </StyleContentTitleContainer>
            <StyledContentContainer>
              <StyledAreaContainer onClick={() => setIsCreating(true)}>
                <StyledPlaneContainer>
                  <Plane />
                </StyledPlaneContainer>
                <StyledSpanContent>
                  <strong>
                    <u>Clique aqui</u>
                  </strong>{' '}
                  para importar os extratos.
                </StyledSpanContent>
              </StyledAreaContainer>
            </StyledContentContainer>
          </StyledRequestsContainer>
        )}

        {!isLoading && !!data.length && (
          <StyledRequestsContainerTable>
            <ExtractTableCollapse
              data={prepareData(data)}
              rows={[
                'transactionIdentifier',
                'transactionDateSent',
                // 'payerName',
                // 'payerIdentify',
                'transactionDescription',
                'transactionAmount',
                'status',
              ]}
              head={[
                'ID',
                'Data',
                // 'Pagador',
                // 'CPF / CNPJ',
                'Descrição',
                'Valor',
                'Status',
                'Comp.',
                'Solicit.',
              ]}
              handlePreview={handlePreviewFile}
              handleRedirect={handleRedirectToRequest}
              handleRedirectPix={handleRedirectPixToRequest}
              handleDownload={() => null}
              page={page}
              itemsPerPage={itemsPerPage}
              changeItemsPerPage={setItemsPerPage}
              changePage={setPage}
              count={count}
              options={(row) => {
                return [
                  {
                    id: 'archive',
                    label: row.archived ? 'Ativar' : 'Arquivar',
                    icon: <ArchiveIcon fontSize="small" />,
                    onClick: (id) => handleArchiveItem(id),
                    disabled: Boolean(row.paymentReceiptId),
                  },
                ];
              }}
            />
          </StyledRequestsContainerTable>
        )}
      </Div2>

      <ModalImportExtract
        title={'Importar extrato'}
        open={isCreating}
        handleSubmit={handleSubmitImportExtract}
        handleClose={() => setIsCreating(false)}
      />

      <ModalPreviewImage
        open={modalPreview.open}
        fileId={modalPreview.fileId}
        url={modalPreview.url}
        typeFile={modalPreview.typeFile}
        payments={modalPreview.payments}
        isLoading={isLoadingPreviewImage}
        handleClose={() => setModalPreview({ ...modalPreview, open: false })}
      />
    </Div>
  );
};

export default Extract;
