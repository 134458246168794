import { FormControlLabel, FormGroup } from '@mui/material';
import styled from 'styled-components';

export const StyledRoot = styled('div')({
  color: '#444',
});

export const StyledTitle = styled('div')({
  color: '#555',
  fontSize: 18,
  fontWeight: 'bold',
});

export const StyledNotFound = styled('div')({
  padding: 20,
  textAlign: 'center',
});

export const StyledItem = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: 16,
});

export const StyledContentButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledLabelSwitch = styled('div')({
  color: '#444',
});

export const StyledFormGroup = styled(FormGroup)({
  color: '#444',
  padding: '8px 16px',
  boxSizing: 'border-box',
  border: '1px solid #ccc',
  borderRadius: 8,
  maxHeight: 400,
  overflow: 'auto',

  flexWrap: 'nowrap !important' as any,
});

export const StyledFormControlLabel = styled(FormControlLabel)({
  transition: '0.3s',
  borderRadius: 8,
  '&:hover': {
    background: '#eee',
  },
});

export const StyledTitleCheckbox = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const StyledLabel = styled.div`
  font-family: Rubik, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  color: #79cc72;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 14px;
`;

export const StyledStatusContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});
