import yup from '../../utils/yup';

export const initialFormValue = {
  title: '',
  url: '',
  description: '',
  airlineProgram: '',
  bankProgram: '',
  startDate: '',
  endDate: '',
  bonusMinimum: '',
  bonusMaximum: '',

  pointsMinimum: '10.000',
  pointsMaximum: '',

  partnerPrice: '',
  customerPrice: '',
};

export interface FormProps {
  title: string;
  url: string;
  description: string;
  airlineProgram: string;
  bankProgram: string;
  startDate: string;
  endDate: string;
  bonusMinimum: string;
  bonusMaximum: string;
  pointsMinimum: string;
  pointsMaximum: string;
  partnerPrice: string;
  customerPrice: string;
}

export const validationSchema = yup.object({
  title: yup.string().required('Campo obrigatório'),
  url: yup
    .string()
    .required('Campo obrigatório')
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'URL inválida'
    ),
  description: yup.string().required('Nome obrigatório'),
  airlineProgram: yup.string().required('Campo obrigatório'),
  bankProgram: yup.string().required('Campo obrigatório'),
  startDate: yup.string().required('Campo obrigatório'),
  endDate: yup.string().required('Campo obrigatório'),
  bonusMinimum: yup.string().required('Campo obrigatório'),
  bonusMaximum: yup.string().required('Campo obrigatório'),

  pointsMinimum: yup.string().required('Campo obrigatório'),
  pointsMaximum: yup.string().required('Campo obrigatório'),

  partnerPrice: yup.string().required('Campo obrigatório'),
  customerPrice: yup.string().required('Campo obrigatório'),
});
