import React, { FC, ReactElement } from 'react';

import {
  StyledCardAccounts,
  StyledContentCardInfo,
  StyledDivider,
} from './styles';
import { formatNumber } from '../../utils/number';

interface Props {
  items?: any[];
}

const CardAccounts: FC<Props> = ({ items }): ReactElement => {
  return (
    <StyledCardAccounts>
      {items?.map((item) => (
        <div
          key={item.id}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <StyledContentCardInfo>
            <div>{item.name}</div>
            <StyledDivider>-</StyledDivider>
            <div>{formatNumber(item.miles)} pontos</div>
          </StyledContentCardInfo>
        </div>
      ))}

      {!items?.length && (
        <div style={{ color: '#878A99' }}>Nenhum comentário adicionado</div>
      )}
    </StyledCardAccounts>
  );
};

export default CardAccounts;
