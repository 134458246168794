import styled from 'styled-components';

export const Div18 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const Div19 = styled.div`
  color: #212529;
  font: 500 16px Poppins, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const Div20 = styled.div`
  color: #878a99;
  margin-top: 12px;
  font: 275 12px Poppins, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const Div25 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
`;

export const Div28 = styled('div')({
  color: '#878a99',
  marginTop: 10,
  font: '300 12px Poppins, sans-serif',
  display: 'flex',
  flexDirection: 'column',
  gap: 4,

  '& > div': {
    color: '#69bc62',
    fontWeight: 500,
  },
});

export const Div32 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-top: 20px;
`;

export const Div37 = styled.div`
  width: 185px;
  border-radius: 4px;
  background-color: #79cc72;
  display: flex;
  padding-left: 13px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-size: 13px;
  color: #fff;
  font-weight: 500;
  margin: auto 0;
  height: 40px;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    background-color: #69bc62;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const Div38 = styled.div`
  font-family: Poppins, sans-serif;
  margin: auto 0;
`;

export const StyledButtonBack = styled.div`
  width: 165px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  padding: 0px 12px;
  gap: 20px;
  font-size: 13px;
  color: #79cc72;
  border: 1px solid #79cc72;
  font-weight: 400;
  margin: auto 0;
  height: 40px;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    background-color: #69bc6222;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const StyledContentFiles = styled.div`
  color: #878a99;
  margin-top: 5px;
  font: italic 300 10px Poppins, sans-serif;

  display: flex;
  flex-direction: column;
`;

export const StyledContentFilesItem = styled.div`
  display: flex;
  align-items: center;
`;

export const GreenSquare = styled('div')({
  width: '100%',
  minHeight: 45,
  border: '1px solid #69bc62',
  borderRadius: 4,

  padding: '8px',
  marginTop: 32,
  color: '#69bc62',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',

  font: '500 12px Poppins, sans-serif',
  textAlign: 'center',

  backgroundColor: '#69bc6222',
});
