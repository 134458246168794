import * as React from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { resendCodeRequest, singin, singinCode } from '../../api/authenticate';
import useAuthenticate from '../../recoil/hooks/authenticate';
import LoginForm from './LoginForm';
import CodeForm from './CodeForm';

const PartnerLogin = (): React.ReactElement => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(false);
  const [token, setToken] = React.useState('');

  const { authenticate, signin } = useAuthenticate();

  React.useEffect(() => {
    if (authenticate?.isAuthenticate) {
      navigate('/home');
    }
  }, [authenticate, navigate]);

  const resendCode = async () => {
    try {
      await resendCodeRequest(token);
    } catch (error: any) {
      toast(
        'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
        { type: 'error' }
      );
    }
  };

  const handleSubmitCode = async (code: string) => {
    setIsLoading(true);
    try {
      const data = await singinCode(token, code);

      signin(data.token);
    } catch (error: any) {
      const message = 'Código de acesso inválido!';
      toast(message, { type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (body: {
    user: string;
    code: string;
    token: string;
  }) => {
    setIsLoading(true);
    try {
      const data = await singin(body);

      setToken(data.token);

      // signin(data.token);
    } catch (error: any) {
      const message = 'Usuário ou senha inválidos!';
      toast(message, { type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  if (token) {
    return (
      <CodeForm
        handleSubmit={handleSubmitCode}
        isLoading={isLoading}
        resendCode={resendCode}
      />
    );
  }

  return <LoginForm handleSubmit={handleSubmit} isLoading={isLoading} />;
};

export default PartnerLogin;
