import styled from 'styled-components';

export const Div = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #79cc72;
  font-weight: 400;
  padding: 50px 60px;
  height: calc(100vh - 55px);
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 991px) {
    padding: 0px 20px;
    height: calc(100vh - 55px);
  }
`;

export const Div2 = styled.div`
  display: flex;
  width: 523px;
  max-width: 100%;
  position: relative;
  flex-direction: column;

  transform: ${window.innerHeight < 800 ? 'scale(0.8)' : 'scale(1)'};
`;

export const Div3 = styled.div`
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 36px;
  font-family: Saira Stencil One, sans-serif;
  margin-left: 12px;

  @media (max-width: 991px) {
    margin-left: 0px;
    text-align: center;
  }
`;

export const Div4 = styled.div`
  border-radius: 22px;
  border: 1px solid #79cc72;
  box-shadow: 0 0 6px 6px rgba(0, 0, 0, 0.25);
  display: flex;
  margin-top: 24px;
  flex-direction: column;
  font-size: 13px;
  padding: 29px 30px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 24px 16px;
  }
  background: linear-gradient(
    60deg,
    rgba(21, 110, 121, 1) 0%,
    rgba(121, 204, 114, 1) 100%
  );
  font-family: Poppins, sans-serif;
`;

export const Div5 = styled.div`
  font: 600 17px Montserrat, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const Div6 = styled.div`
  color: #fff;
  font-family: Rubik, sans-serif;
  font-weight: 600;
  margin-top: 24px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  font-family: Poppins, sans-serif;
`;

export const Div7 = styled.div`
  font-family: Rubik, sans-serif;
  margin-top: 24px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  color: #fff;
  font-family: Poppins, sans-serif;
  font-weight: 500;
`;

export const Div9 = styled.div`
  font-family: Rubik, sans-serif;
  margin-top: 30px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const BemVindo = styled.span`
  font-weight: 600;
  color: #fff;
  font-family: Poppins, sans-serif;
`;

export const MilhasConta = styled.span`
  color: #fff;
  font-family: Poppins, sans-serif;
`;

export const FormLoginStyled = styled.form`
  width: 100%;
  height: 100%;
`;

export const PlaneContainer = styled('div')({
  '@media (max-width: 991px)': {
    display: 'none',
  },
  '> svg': {
    position: 'absolute',
    top: '-115px',
    right: '-140px',
    width: '387px',
  },
});

export const StyleLinkPartnerContent = styled('div')({
  position: 'absolute',
  top: 10,
  right: 10,
});

export const StyleLinkPartner = styled('div')({
  padding: 10,
  boxSizing: 'border-box',
  borderRadius: 12,
  font: '600 14px Poppins, sans-serif',
  cursor: 'pointer',
  transition: '0.4s',
  '&:hover': {
    background: '#79cc7233',
  },
});

export const StyledVersionHeader = styled('div')({
  color: '#555',
  position: 'absolute',
  font: '300 12px Poppins, sans-serif',
  top: 10,
  right: 10,
});

export const StyledContentButton = styled('div')({
  marginTop: 40,
});
