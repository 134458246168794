import React, { HTMLInputTypeAttribute } from 'react';
import styled from 'styled-components';
import { StyledError, StyledLabel } from './styles';

const InputContainer = styled.div`
  display: flex;
`;

const InputField = styled('input')({
  borderRadius: '4px',
  border: '1px solid #ced4da',
  justifyContent: 'center',
  alignItems: 'start',
  whiteSpace: 'nowrap',
  padding: '13px 60px 13px 15px',
  backgroundColor: '#fff',
  width: '100%',
  fontSize: '14px',
  fontFamily: 'Poppins, sans-serif',

  '@media (max-width: 991px)': {
    whiteSpace: 'initial',
    maxWidth: '100%',
    paddingRight: '20px',
  },

  '&::placeholder': {
    color: 'rgba(21, 110, 121, 0.5)',
  },
  color: 'rgba(21, 110, 121, 1)',

  '&:disabled': {
    background: '#ccc !important',
    pointerEvents: 'none',
  },
});

interface NewInputProps {
  placeholder?: string;
  value?: string;
  onChange?: (event?: any) => void;
  name?: string;
  type?: HTMLInputTypeAttribute;
  disabled?: boolean;
  label?: string;
  onBlur?: (event: any) => void;
  onKeyDown?: (event: any) => void;
  error?: string;
  min?: number;
  max?: number;
}

const NewInput = ({
  placeholder,
  value,
  onChange,
  name,
  disabled,
  type = 'text',
  label,
  onBlur,
  onKeyDown,
  error,
  min,
  max,
}: NewInputProps): any => {
  return (
    <React.Fragment>
      {!!label && <StyledLabel>{label}</StyledLabel>}
      <InputContainer>
        <InputField
          type={type}
          disabled={disabled}
          value={value}
          onChange={onChange}
          defaultValue={value}
          placeholder={placeholder}
          name={name}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          min={min}
          max={max}
        />
      </InputContainer>
      {!!error && <StyledError>{error}</StyledError>}
    </React.Fragment>
  );
};

export default NewInput;
