import * as React from 'react';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import SubmitButton from '../../components/Button';
import SimpleInput from './Input';

import {
  Div,
  Div2,
  Div3,
  Div4,
  Div5,
  Div6,
  Div7,
  BemVindo,
  MilhasConta,
  FormLoginStyled,
  PlaneContainer,
  StyledVersionHeader,
} from './styles';
import Plane from '../../images/Plane';
import { codeAccess } from '../../api/authenticate';
import useAuthenticate from '../../recoil/hooks/authenticate';
import { cpfMask, removeCpfMask } from '../../utils/cpf';
import config from '../../config/config';

const Login = (): React.ReactElement => {
  const navigate = useNavigate();

  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  const [searchParams] = useSearchParams();
  const codeParam = searchParams.get('code');

  const [isLoading, setIsLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    user: '',
    email: '',
    code: codeParam || '',
  });

  const { authenticate, signin, logout } = useAuthenticate();

  React.useEffect(() => {
    if (authenticate?.isAuthenticate) {
      if (codeParam && authenticate?.user?.code !== codeParam) {
        logout();
        toast('Para continuar, entre utilizando o código abaixo! ', {
          type: 'info',
        });
        return;
      }

      if (authenticate?.showLandingPage) {
        navigate('/landing-page');
      } else {
        navigate('/home');
      }
    }
  }, [authenticate, codeParam, logout, navigate]);

  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();
      setIsLoading(true);

      const { user, code, email } = formData;

      const codeValue = codeParam || code;

      if (!user?.length || !codeValue?.length || !email.length) {
        toast('Por favor, preencha os campos antes de submeter o formulário.', {
          type: 'error',
        });
        return;
      }

      const cpf = removeCpfMask(user);

      const token = await recaptchaRef?.current?.executeAsync();

      const data = await codeAccess({
        user: cpf,
        code: codeValue,
        email,
        token: token as string,
      });

      signin(data.token, data.showLandingPage);
    } catch (error: any) {
      const message = 'Usuário ou código invalido!';
      toast(message, { type: 'error' });
    } finally {
      recaptchaRef?.current?.reset();
      setIsLoading(false);
    }
  };

  const handleChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCpf = (event: any): void => {
    setFormData({
      ...formData,
      user: cpfMask(event.target.value),
    });
  };

  return (
    <Div>
      <StyledVersionHeader>v{config.version}</StyledVersionHeader>
      <Div2>
        <Div3>Milhas na conta</Div3>

        <PlaneContainer>
          <Plane />
        </PlaneContainer>

        <Div4>
          <Div5>
            <BemVindo>Bem-vindo ao </BemVindo>

            <MilhasConta>Milhas na conta!</MilhasConta>
          </Div5>

          <Div6>Conecte-se para acessar o painel.</Div6>

          <FormLoginStyled>
            <Div7>CPF ou CNPJ</Div7>
            <SimpleInput
              placeholder={'Digite seu usuário (CPF ou CNPJ)'}
              name="user"
              onChange={handleChangeCpf}
              value={formData.user}
            />
            <Div7>E-mail</Div7>
            <SimpleInput
              placeholder={'Digite seu e-mail'}
              name="email"
              type="email"
              onChange={handleChange}
              value={formData.email}
            />
            <Div7>Código</Div7>
            <SimpleInput
              placeholder={'Digite seu código de acesso'}
              name="code"
              onChange={codeParam ? undefined : handleChange}
              disabled={!!codeParam}
              value={codeParam || formData.code}
            />

            <SubmitButton onClick={handleSubmit} disabled={isLoading}>
              {isLoading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <span>Entrar</span>
              )}
            </SubmitButton>
          </FormLoginStyled>
        </Div4>
      </Div2>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={config.recaptcha}
      />
    </Div>
  );
};

export default Login;
