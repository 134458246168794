export enum RequestStatusEnum {
  all = 'all',
  open = 'open',
  pending = 'pending',
  analysing = 'analysing',
  confirmed = 'confirmed',
  waitingCredit = 'waitingCredit',
  completed = 'completed',
  canceled = 'canceled',
}

export const requestStatusLabel = (status: RequestStatusEnum): string => {
  const options = {
    all: 'Todos',
    open: 'Em aberto',
    pending: 'Pendente',
    analysing: 'Em análise',
    confirmed: 'Confirmado',
    waitingCredit: 'Aguardando crédito',
    completed: 'Concluído',
    canceled: 'Cancelado',
  };

  return options[status] || options.pending;
};

export const requestStatusColor = (status: RequestStatusEnum): string => {
  const options = {
    all: '#fff',
    open: '#fff',
    pending: '#B6B6B6',
    analysing: '#E9D949',
    confirmed: '#79CC72',
    waitingCredit: '#009688',
    completed: '#3F853A',
    canceled: '#b2102f',
  };

  return options[status] || options.confirmed;
};
