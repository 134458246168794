import React, { ReactElement, useEffect } from 'react';
import { toast } from 'react-toastify';

import RemoveCircle from '@mui/icons-material/RemoveCircle';
import { FormControlLabel, Switch, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Add';
import {
  StyledContentButtons,
  StyledFormGroup,
  StyledItem,
  StyledLabelSwitch,
  StyledNotFound,
  StyledRoot,
  StyledTitle,
} from './styles';
import AttachmentButton from '../AttachmentButton';
import SimpleInput from '../../pages/Login/Input';
import useAuthenticate from '../../recoil/hooks/authenticate';
import { uploadFileLandingPage } from '../../api/landing-page';
import SubmitButton, { Button, ButtonWithIcon } from '../Button';

interface Config {
  images: {
    mobile: string;
    desktop: string;
  };
  callToAction: {
    text: string;
    url: string;
  };
  rules: {
    url: string;
  };
  date: string;
}

const defaultValue: Config = {
  images: {
    mobile: '',
    desktop: '',
  },
  callToAction: {
    text: '',
    url: '',
  },
  rules: {
    url: '',
  },
  date: '',
};

interface Values {
  hero: Config[];
  campaignEnabled: boolean;
}

interface Props {
  data: Values;
  handleSubmit: (values: Values) => void;
  handleCancelEdit: () => void;
}

const FormSettingsLandingPage = ({
  data,
  handleSubmit,
  handleCancelEdit,
}: Props): ReactElement => {
  const { authenticate } = useAuthenticate();

  const [values, setValues] = React.useState<Values>({
    hero: [],
    campaignEnabled: false,
  });

  const handleAddValue = () => {
    setValues({
      ...values,
      hero: [...values.hero, defaultValue],
    });
    setTimeout(() => {
      document?.getElementById(`item-${values.hero.length}`)?.scrollIntoView({
        behavior: 'smooth',
      });
    }, 200);
  };

  useEffect(() => {
    if (data) {
      setValues(data);
    }
  }, [data]);

  const handleChangeCallToAction =
    (index: number) =>
    (event: any): void => {
      const newValues = values.hero.map((item, indexMap) => {
        if (indexMap === index) {
          return {
            ...item,
            callToAction: {
              ...item.callToAction,
              [event.target.name]: event.target.value,
            },
          };
        }

        return item;
      });

      setValues({
        ...values,
        hero: newValues,
      });
    };

  const handleChangeRules =
    (index: number) =>
    (event: any): void => {
      const newValues = values.hero.map((item, indexMap) => {
        if (indexMap === index) {
          return {
            ...item,
            rules: {
              ...item.rules,
              [event.target.name]: event.target.value,
            },
          };
        }

        return item;
      });

      setValues({
        ...values,
        hero: newValues,
      });
    };

  const handleChange =
    (index: number) =>
    (event: any): void => {
      const newValues = values.hero.map((item, indexMap) => {
        if (indexMap === index) {
          // eslint-disable-next-line no-param-reassign
          return {
            ...item,
            [event.target.name]: event.target.value,
          };
        }

        return item;
      });

      setValues({
        ...values,
        hero: newValues,
      });
    };

  const removeItemDesktop = (index: number): void => {
    const newValues = values.hero.map((item, indexMap) => {
      if (indexMap === index) {
        // eslint-disable-next-line no-param-reassign
        return {
          ...item,
          images: {
            ...item.images,
            desktop: '',
          },
        };
      }

      return item;
    });

    setValues({
      ...values,
      hero: newValues,
    });
  };

  const removeItemMobile = (index: number): void => {
    const newValues = values.hero.map((item, indexMap) => {
      if (indexMap === index) {
        // eslint-disable-next-line no-param-reassign
        return {
          ...item,
          images: {
            ...item.images,
            mobile: '',
          },
        };
      }

      return item;
    });

    setValues({
      ...values,
      hero: newValues,
    });
  };

  const removeItemCampaign = (index: number): void => {
    const newValues = values.hero
      .map((item, indexMap) => {
        if (indexMap === index) {
          return null;
        }

        return item;
      })
      .filter((item) => Boolean(item));

    setValues({
      ...values,
      hero: newValues as Config[],
    });
  };

  const handleUploadImage = async (files: FileList): Promise<string[]> => {
    try {
      const result = await uploadFileLandingPage(files, authenticate.token);

      return result.data;
    } catch (error) {
      toast(
        'Não foi possível fazer o upload do arquivo. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
      return [];
    }
  };

  const handleSelectFile =
    (step: string, index: number) =>
    async (files: FileList): Promise<void> => {
      const urls = await handleUploadImage(files);

      const url = urls[0];

      const newValues = values.hero.map((item, indexMap) => {
        if (indexMap === index) {
          if (step === 'desktop') {
            // eslint-disable-next-line no-param-reassign
            item.images.desktop = url;
          }
          if (step === 'mobile') {
            // eslint-disable-next-line no-param-reassign
            item.images.mobile = url;
          }
          return item;
        }

        return item;
      });

      setValues({
        ...values,
        hero: newValues,
      });
    };

  const submit = () => {
    handleSubmit(values);
  };

  return (
    <StyledRoot>
      <StyledTitle>Possuí campanha habilitada</StyledTitle>
      <StyledFormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={values.campaignEnabled}
              onChange={(event) =>
                setValues({
                  ...values,
                  campaignEnabled: event.target.checked,
                })
              }
            />
          }
          label={
            <StyledLabelSwitch>
              {values?.campaignEnabled
                ? 'Possuí campanha habilitada'
                : 'Nenhuma campanha habilitada'}
            </StyledLabelSwitch>
          }
        />
      </StyledFormGroup>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 16,
        }}
      >
        <StyledTitle>Campanhas</StyledTitle>
        <ButtonWithIcon
          label="Adicionar"
          icon={<EditIcon />}
          onClick={() => handleAddValue()}
        />
      </div>

      {!values.hero?.length && (
        <StyledNotFound>Nenhuma campanha adicionada ainda!</StyledNotFound>
      )}

      {values.hero.map((item, index) => (
        <div
          key={index}
          id={`item-${index}`}
          style={{
            border: '1px solid #ccc',
            borderRadius: 8,
            padding: 32,
            marginBottom: 16,
            position: 'relative',
          }}
        >
          <Tooltip title="Remover campanha" arrow>
            <RemoveCircle
              htmlColor="red"
              onClick={() => removeItemCampaign(index)}
              style={{
                position: 'absolute',
                top: 10,
                right: 10,
                cursor: 'pointer',
              }}
            />
          </Tooltip>
          <StyledTitle>Imagem mobile</StyledTitle>
          <StyledItem>
            {!item?.images?.mobile && (
              <React.Fragment>
                <AttachmentButton
                  width={350}
                  acceptTypes="image/*"
                  handleChange={handleSelectFile('mobile', index)}
                  label="Adicione a imagem para mobile"
                />
                <StyledNotFound>
                  Nenhuma imagem adicionada ainda!
                </StyledNotFound>
              </React.Fragment>
            )}

            <div
              style={{
                display: 'flex',
                overflowX: 'auto',
                gap: 16,
                padding: 16,
                maxWidth: '100%',
              }}
            >
              {!!item?.images?.mobile && (
                <div style={{ width: 200, position: 'relative' }}>
                  <img src={`${item?.images?.mobile}`} style={{ width: 200 }} />
                  <Tooltip title="Remover imagem" arrow>
                    <RemoveCircle
                      htmlColor="red"
                      onClick={() => removeItemMobile(index)}
                      style={{
                        position: 'absolute',
                        top: -16,
                        right: -10,
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                </div>
              )}
            </div>
          </StyledItem>

          <StyledTitle>Imagem desktop</StyledTitle>
          <StyledItem>
            {!item?.images?.desktop && (
              <React.Fragment>
                <AttachmentButton
                  width={350}
                  acceptTypes="image/*"
                  handleChange={handleSelectFile('desktop', index)}
                  label="Adicione a imagem para desktop"
                />
                <StyledNotFound>
                  Nenhuma imagem adicionada ainda!
                </StyledNotFound>
              </React.Fragment>
            )}

            <div
              style={{
                display: 'flex',
                overflowX: 'auto',
                gap: 16,
                padding: 16,
                maxWidth: '100%',
              }}
            >
              {!!item?.images?.desktop && (
                <div style={{ width: 200, position: 'relative' }}>
                  <img
                    src={`${item?.images?.desktop}`}
                    style={{ width: 200 }}
                  />
                  <Tooltip title="Remover imagem" arrow>
                    <RemoveCircle
                      htmlColor="red"
                      onClick={() => removeItemDesktop(index)}
                      style={{
                        position: 'absolute',
                        top: -16,
                        right: -10,
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                </div>
              )}
            </div>
          </StyledItem>

          <StyledTitle>Data da campanha</StyledTitle>
          <SimpleInput
            placeholder="Data da campanha"
            name="date"
            onChange={handleChange(index)}
            value={item.date}
          />

          <StyledTitle>URL destino</StyledTitle>
          <SimpleInput
            placeholder="URL destino"
            name="url"
            onChange={handleChangeCallToAction(index)}
            value={item.callToAction.url}
          />

          <StyledTitle>URL regulamento</StyledTitle>
          <SimpleInput
            placeholder="URL regulamento"
            name="url"
            onChange={handleChangeRules(index)}
            value={item.rules.url}
          />
        </div>
      ))}

      <StyledContentButtons>
        <div style={{ width: '45%' }}>
          <Button onClick={handleCancelEdit} disabled={false}>
            {/* {isLoading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <span>Cancelar</span>
            )} */}
            <span>Cancelar</span>
          </Button>
        </div>
        <div style={{ width: '45%' }}>
          <SubmitButton onClick={submit} disabled={false}>
            {/* {isLoading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <span>Salvar</span>
            )} */}
            <span>Salvar</span>
          </SubmitButton>
        </div>
      </StyledContentButtons>
    </StyledRoot>
  );
};

export default FormSettingsLandingPage;
