import styled from 'styled-components';

import { Box } from '@mui/material';

export const StyledBox = styled(Box)({
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  borderRadius: 4,
  color: '#212529',
  background: '#fff',
  padding: 32,
  boxSizing: 'border-box',
  p: 4,
});

export const FormLoginStyled = styled.form`
  width: 100%;
  height: 100%;
  padding-top: 20px;
`;

export const Div7 = styled.div`
  font-family: Rubik, sans-serif;
  margin-top: 32px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const Div9 = styled.div`
  font-family: Rubik, sans-serif;
  margin-top: 30px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const StyledContentButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;
