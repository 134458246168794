/* eslint-disable no-underscore-dangle */
import React, { ReactElement, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Checkbox } from '@mui/material';
import {
  StyledContentButtons,
  StyledFormControlLabel,
  StyledFormGroup,
  StyledLabel,
  StyledRoot,
  StyledTitle,
  StyledTitleCheckbox,
} from './styles';
import SubmitButton, { Button } from '../Button';
import {
  StyledForm,
  StyledFormInput,
  StyledFormInputs,
} from '../../pages/NewRequestV3/StepOne/Form/styles';
import NewInput from '../../pages/NewRequestV3/StepOne/Form/Input';
import TextArea from '../../pages/NewRequestV3/StepOne/Form/TextArea';
import { initialFormValue, validationSchema } from './schema';
import NewSelect from '../../pages/NewRequestV3/StepOne/Form/Select';
import { formatNumber, formatNumberPercent } from '../../utils/number';
import { formatMoney, moneyMask } from '../../utils/money';
import { removeMask } from '../../utils/cpf';

interface Props {
  users: any[];
  campaignToEdit?: any;
  handleSubmit: (values: any) => void;
  handleCancelEdit: () => void;
}

const FormPromotions = ({
  users,
  campaignToEdit,
  handleSubmit,
  handleCancelEdit,
}: Props): ReactElement => {
  const [usersSelected, setUsersSelected] = useState<string[]>([]);

  const formik = useFormik({
    initialValues: initialFormValue,
    validationSchema,
    onSubmit: () => {
      const { values } = formik;

      handleSubmit({
        ...values,
        usersSelected,

        bonusMaximum: removeMask(values.bonusMaximum),
        bonusMinimum: removeMask(values.bonusMinimum),
        customerPrice: removeMask(values.customerPrice),
        partnerPrice: removeMask(values.partnerPrice),
        pointsMaximum: removeMask(values.pointsMaximum),
        pointsMinimum: removeMask(values.pointsMinimum),
      });
    },
  });

  const formatDateToISO = (date: Date): string => {
    if (!(date instanceof Date)) {
      throw new TypeError('The input must be a valid Date object');
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  useEffect(() => {
    if (campaignToEdit) {
      if (campaignToEdit.title) {
        formik.setFieldValue('title', campaignToEdit.title);
      }

      if (campaignToEdit.description) {
        formik.setFieldValue('description', campaignToEdit.description);
      }

      if (campaignToEdit.url) {
        formik.setFieldValue('url', campaignToEdit.url);
      }

      if (campaignToEdit.airlineProgram) {
        formik.setFieldValue('airlineProgram', campaignToEdit.airlineProgram);
      }

      if (campaignToEdit.bankProgram) {
        formik.setFieldValue('bankProgram', campaignToEdit.bankProgram);
      }

      if (campaignToEdit.startDate) {
        const date = new Date(campaignToEdit.startDate);
        formik.setFieldValue('startDate', formatDateToISO(date));
      }

      if (campaignToEdit.endDate) {
        const date = new Date(campaignToEdit.endDate);
        formik.setFieldValue('endDate', formatDateToISO(date));
      }

      if (campaignToEdit.bonusMinimum) {
        formik.setFieldValue(
          'bonusMinimum',
          formatNumberPercent(campaignToEdit.bonusMinimum)
        );
      }

      if (campaignToEdit.bonusMaximum) {
        formik.setFieldValue(
          'bonusMaximum',
          formatNumberPercent(campaignToEdit.bonusMaximum)
        );
      }

      if (campaignToEdit.pointsMinimum) {
        formik.setFieldValue(
          'pointsMinimum',
          formatNumber(campaignToEdit.pointsMinimum)
        );
      }

      if (campaignToEdit.pointsMaximum) {
        formik.setFieldValue(
          'pointsMaximum',
          formatNumber(campaignToEdit.pointsMaximum)
        );
      }

      if (campaignToEdit.customerPrice) {
        formik.setFieldValue(
          'customerPrice',
          formatMoney(campaignToEdit.customerPrice)
        );
      }

      if (campaignToEdit.partnerPrice) {
        formik.setFieldValue(
          'partnerPrice',
          formatMoney(campaignToEdit.partnerPrice)
        );
      }

      if (campaignToEdit.userCode?.length) {
        setUsersSelected(
          campaignToEdit.userCode
            .filter((item: any) => item.status === 'active')
            .map((item: any) => item.user.id)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignToEdit]);

  const handleSelectUser = (id: string) => {
    if (usersSelected.includes(id)) {
      setUsersSelected(usersSelected.filter((item) => item !== id));
    } else {
      setUsersSelected([...usersSelected, id]);
    }
  };

  const handleChange = (event: any): void => {
    const { value, name } = event.target;

    formik.setFieldValue(name, value);
  };

  const handleChangeNumber = (event: any): void => {
    const { value, name } = event.target;
    formik.setFieldValue(name, formatNumber(value));
  };

  const handleChangeNumberPercent = (event: any): void => {
    const { value, name } = event.target;
    formik.setFieldValue(name, formatNumberPercent(value));
  };

  const handleKeyDownChangeNumberPercent = (event: any): void => {
    const { value, name } = event.target;

    if (event.code === 'Backspace') {
      formik.setFieldValue(name, formatNumberPercent(value.slice(0, -2)));
    }
  };

  const handleChangeMoney = (event: any): void => {
    const { value, name } = event.target;
    formik.setFieldValue(name, moneyMask(value));
  };

  const [checkboxGeneral, setCheckboxGeneral] = useState(false);

  const handleChangeCheckbox = () => {
    if (usersSelected?.length === users?.length) {
      setUsersSelected([]);
      setCheckboxGeneral(false);
    } else {
      setUsersSelected(users?.map((item) => item._id));
      setCheckboxGeneral(true);
    }
  };

  useEffect(() => {
    if (usersSelected?.length === users?.length) {
      setCheckboxGeneral(true);
    }

    if (usersSelected?.length === 0) {
      setCheckboxGeneral(false);
    }
  }, [usersSelected, users]);

  return (
    <StyledRoot>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 16,
        }}
      >
        <StyledTitle>
          {campaignToEdit ? 'Editar campanha' : 'Adicionar campanha'}
        </StyledTitle>
      </div>

      <div
        style={{
          borderRadius: 8,
          marginBottom: 16,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
        }}
      >
        <StyledForm onSubmit={formik.handleSubmit}>
          <StyledFormInputs>
            <StyledFormInput>
              <NewInput
                placeholder="Título da promoção"
                name="title"
                label="Título da promoção *"
                value={formik.values.title}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.title ? formik.errors.title : ''}
              />
            </StyledFormInput>

            <StyledFormInput>
              <NewInput
                placeholder="Página da promoção"
                name="url"
                type="url"
                label="Página da promoção *"
                value={formik.values.url}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.url ? formik.errors.url : ''}
              />
            </StyledFormInput>
          </StyledFormInputs>

          <StyledFormInput>
            <TextArea
              placeholder="Descrição"
              name="description"
              label="Descrição *"
              value={formik.values.description}
              onChange={handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.description ? formik.errors.description : ''
              }
            />
          </StyledFormInput>

          <StyledFormInputs>
            <StyledFormInput>
              <NewSelect
                name="bankProgram"
                label="Programa de banco *"
                options={[
                  {
                    label: 'Inter',
                    value: 'Inter',
                  },
                  {
                    label: 'Esfera',
                    value: 'Esfera',
                  },
                  {
                    label: 'C6',
                    value: 'C6',
                  },
                  {
                    label: 'Livelo',
                    value: 'Livelo',
                  },
                  {
                    label: 'Outros',
                    value: 'Outros',
                  },
                ]}
                value={formik.values.bankProgram}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.bankProgram ? formik.errors.bankProgram : ''
                }
              />
            </StyledFormInput>
            <StyledFormInput>
              <NewSelect
                name="airlineProgram"
                label="Programa da aérea *"
                options={[
                  {
                    label: 'Azul',
                    value: 'Azul',
                  },
                  {
                    label: 'Smiles',
                    value: 'Smiles',
                  },
                  {
                    label: 'Latam',
                    value: 'Latam',
                  },
                  {
                    label: 'Iberia',
                    value: 'Iberia',
                  },
                  {
                    label: 'Outros',
                    value: 'Outros',
                  },
                ]}
                value={formik.values.airlineProgram}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.airlineProgram
                    ? formik.errors.airlineProgram
                    : ''
                }
              />
            </StyledFormInput>
          </StyledFormInputs>

          <StyledFormInputs>
            <StyledFormInput>
              <NewInput
                placeholder="Data de início"
                name="startDate"
                type="datetime-local"
                label="Data de início *"
                value={formik.values.startDate}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.startDate ? formik.errors.startDate : ''}
              />
            </StyledFormInput>
            <StyledFormInput>
              <NewInput
                placeholder="Data de término"
                name="endDate"
                type="datetime-local"
                label="Data de término *"
                value={formik.values.endDate}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.endDate ? formik.errors.endDate : ''}
              />
            </StyledFormInput>
          </StyledFormInputs>

          <StyledFormInputs>
            <StyledFormInput>
              <NewInput
                placeholder="Bônus mínimo"
                name="bonusMinimum"
                label="Bônus mínimo *"
                value={formik.values.bonusMinimum}
                onChange={handleChangeNumberPercent}
                onKeyDown={handleKeyDownChangeNumberPercent}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.bonusMinimum ? formik.errors.bonusMinimum : ''
                }
              />
            </StyledFormInput>
            <StyledFormInput>
              <NewInput
                placeholder="Bônus máximo"
                name="bonusMaximum"
                label="Bônus máximo *"
                value={formik.values.bonusMaximum}
                onChange={handleChangeNumberPercent}
                onKeyDown={handleKeyDownChangeNumberPercent}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.bonusMaximum ? formik.errors.bonusMaximum : ''
                }
              />
            </StyledFormInput>
          </StyledFormInputs>

          <StyledFormInputs>
            <StyledFormInput>
              <NewInput
                placeholder="Pontuação mínima"
                name="pointsMinimum"
                label="Pontuação mínima *"
                value={formik.values.pointsMinimum}
                onChange={handleChangeNumber}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.pointsMinimum
                    ? formik.errors.pointsMinimum
                    : ''
                }
              />
            </StyledFormInput>
            <StyledFormInput>
              <NewInput
                placeholder="Pontuação máxima"
                name="pointsMaximum"
                label="Pontuação máxima *"
                value={formik.values.pointsMaximum}
                onChange={handleChangeNumber}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.pointsMaximum
                    ? formik.errors.pointsMaximum
                    : ''
                }
              />
            </StyledFormInput>
          </StyledFormInputs>

          <StyledFormInputs>
            <StyledFormInput>
              <NewInput
                placeholder="Preço do parceiro"
                name="partnerPrice"
                label="Preço do parceiro *"
                value={formik.values.partnerPrice}
                onChange={handleChangeMoney}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.partnerPrice ? formik.errors.partnerPrice : ''
                }
              />
            </StyledFormInput>
            <StyledFormInput>
              <NewInput
                placeholder="Preço do cliente"
                name="customerPrice"
                label="Preço do cliente *"
                value={formik.values.customerPrice}
                onChange={handleChangeMoney}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.customerPrice
                    ? formik.errors.customerPrice
                    : ''
                }
              />
            </StyledFormInput>
          </StyledFormInputs>

          <div>
            <StyledTitleCheckbox>
              <Checkbox
                onChange={handleChangeCheckbox}
                checked={checkboxGeneral}
                indeterminate={
                  usersSelected?.length > 0 &&
                  usersSelected?.length !== users?.length
                }
              />
              <StyledLabel>Administradores</StyledLabel>
            </StyledTitleCheckbox>

            <StyledFormGroup>
              {users?.map((item) => (
                <StyledFormControlLabel
                  key={item._id}
                  control={<Checkbox />}
                  onChange={() => handleSelectUser(item._id)}
                  checked={usersSelected.includes(item._id)}
                  label={`${item.name} <${item.email}>`}
                />
              ))}
            </StyledFormGroup>
          </div>

          <StyledContentButtons>
            <div style={{ width: '45%' }}>
              <Button onClick={handleCancelEdit} disabled={false}>
                <span>Cancelar</span>
              </Button>
            </div>
            <div style={{ width: '45%' }}>
              <SubmitButton disabled={false}>
                <span>Salvar</span>
              </SubmitButton>
            </div>
          </StyledContentButtons>
        </StyledForm>
      </div>
    </StyledRoot>
  );
};

export default FormPromotions;
